import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { UserMessageApiService } from '@cogent/client/shared/services/api/user-message-api.service';
import { SmsComposeModalComponent } from '../../communication/sms-compose-modal/sms-compose-modal.component';

@Component({
  selector: 'app-phone-link-dial-options',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatIconModule, MatButtonModule, MatTabsModule, MatFormFieldModule, FormsModule, MatInputModule],
  templateUrl: './phone-link-dial-options.component.html',
  styleUrl: './phone-link-dial-options.component.scss'
})
export class PhoneLinkDialOptionsComponent {
    readonly dialogRef = inject(MatDialogRef<PhoneLinkDialOptionsComponent>);
    readonly phoneNumber = inject<string>(MAT_DIALOG_DATA);


    constructor(private dialog: MatDialog) { }

    dialNumber() {
        this.dialogRef.close('dial');
    }

    showSMS() {
        this.dialog.open(SmsComposeModalComponent, { data: { to: this.phoneNumber } });
        this.dialogRef.close('sms');
    }


}
