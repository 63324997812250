import { ExecutorInputParams, ExecutorOutputParams, IQuestionRenderer, IStepExecutor, QuestionRenderInputArgs, typeMap } from '../../../../../node/logic/functions/function-runner.model';
import { ApiService, ResultExtractionType } from '@cogent/client/api';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { EventArguments, UndoArguments } from '@cogent/client/shared/services/mission-service-args';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { SmsComposeArgs } from '@cogent/shared/models/communication/sms-compose-args.model';
import { SlyBroadcastMessage } from '@cogent/shared/models/user/sly-broadcast.model';
import * as Sqrl from 'squirrelly';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { Router } from '@angular/router';
import { AuthorizationInputArgs, AuthorizationRepairItemSummary, AuthorizationReviewTrigger, CostLine, CustomFunction, Entity, MessageAttachment, Note, RepairType, RepairTypeGuidelinePrice, Singleton, Task, UserNotification, WorkOrderLineAuthorizationRepairItem, WorkflowInstance } from '@upkeeplabs/models/cogent';
import { FunctionRunner } from '../../../../../node/logic/functions/function-runner.logic';
import { WorkOrderAttachmentModel } from '@cogent/shared/models/service/work-order-attachment.model';
import { LazyComponentProviderService } from '@cogent/client/shared/services/lazy-component-provider.service';
import { MatDialog } from '@angular/material/dialog';

export function getSingletonFunction(): (runner: FunctionRunner) => Promise<Singleton> {
    return async (functionRunner: FunctionRunner) => {
        const api: ApiService = functionRunner.dependencies.api;

        return await api.getSingleNode(`common/Singleton`);
    }
}

export function getAngularExecutors() {
    return [
        new EmailFunctionAngular(),
        new AutoCallFunction(),
        new BroadcastApplicationMessageFunction(),
        new DialNumberFunction(),
        new RunWorkflowFunction(),
        new SendCogentNotification(),
        new SendSMSNotificationFunction(),
        new SendSlyDialFunction(),
        new BindTemplateFunction(),
        new ShowToastSuccessFunction(),
        new ShowAlertDialogFunction(),
        new ShowConfirmDialog(),
        new HasRepairItemFunction(),
        new ShowToastFunction(),
        new SingleItemSelectFunction(),
        new CheckListFunction(),
        new MultilineTextBoxFunction(),
        new TextBoxFunction(),
        new NumberBoxFunction(),
        new RepairItemFunction(),
        new BrandFunction(),
        new DateBoxFunction(),
        new UploadImageFunction(),
        new RichTextBoxFunction(),
        new SliderFunction(),
        new EmailBoxFunction(),
        new PhoneBoxFunction(),
        new CheckBoxFunction(),
        new ShowMessageFunction(),
        new ShowLottieFileFunction(),
        new PlayYouTube(),
        new ShowImage(),
        new TagSelectionFunction(),
        new TimeSlotSelection(),
        new NavigateFunction(),
        new ShowUndoFunction(),
        new WeatherForecastFunction(),
        new RESTFunction(),
        new ConnectTaskFunction(),
        new WaitFunction(),
        new AddTagsFunction(),
        new RemoveTagsFunction(),
        new HasTagsFunction(),
        new PhoneLinkFunction(),
        new HeadingFunction(),
        new SeparatorFunction(),
        new ConfirmButtonFunction(),
        new CreateNoteFunction(),
        new TransferFunction(),
        new CheckCircleAnimatedFunction(),
        new CreateTaskFunctionAngular(),
        new RefreshFunction(),
        new RefreshAllFunction(),
        new ChangeJobFunction(),
        new RemoveFromQueueFunction(),
        new SaveObjectFunction(),
        new AssignWorkOrderFunction(),
        new ChatFunction(),
        new StarRatingFunction(),
        new GetJobItemSelectionFunction(),
        new ShowJobItemQuestionsFunction(),
        new GetUserPoliciesFunction(),
        new GetCreditCardsFunction(),
        new ConfirmJobContact(),
        new CustomComponentFunction(),
        new NewIdFunction(),
        new HyperlinkFunction(),
        new StopConnectContactFunction(),
        new AddressEntryFunction(),
        new PlanSelectionFunction(),
        new AIChatFunction(),
        new ChangeLane(),
        new CopyClipboardFunction(),
        new CloseObjectDetailFunction(),
        new FindPolicyFunction(),
        new GetObjectFunction(),
        new CreateAuthorizationLineFunction(),
        new AuthoItemSelectionFunction(),
        new AuthoItemAttributeFunction(),
        new FileUploadFunction(),
        new RepairTypeSelectionFunction(),
        new WorkOrderItemTypeSelectionFunction(),
        new TransferToRepairTypeQuestionsFunction(),
        new ChangeRepairTypeSelectionFunction(),
    ];
}

export class NewIdFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const result = new ExecutorOutputParams();
        result.result = UtilitiesService.newid();
        return result;
    }
    type = typeMap.newid;
}

export class GetObjectFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;
        const id = inputParams.inputs[0];
        const objectType = inputParams.inputs[1];

        const object = await api.getSingleNode(`${objectType}/${id}`);
        const result = new ExecutorOutputParams();
        result.result = object;
        return result;
    }
    type = typeMap.getObject;
}

export class CreateAuthorizationLineFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {

        const api: ApiService = inputParams.dependencies.api;
        const costLines: CostLine[] = [];
        const result = new ExecutorOutputParams();
        let workOrderLineId = inputParams.inputs[0];
        if (!workOrderLineId) {
            workOrderLineId = inputParams.objectInScope.workOrderLineId;
        }
        if (workOrderLineId) {
            const entity = await api.getSingleNode(`Authorization/get-entity-by-work-order-line/${workOrderLineId}`, null, () => new Entity());
            const items = await api.getArrayNode(`Authorization/authorization-repair-items-by-line/${workOrderLineId}`, null, () => new WorkOrderLineAuthorizationRepairItem());
            const authItems: AuthorizationRepairItemSummary[] = inputParams.inputs[1];

            for (let authItem of authItems) {
                authItem = await api.getSingleNode(`authorization/authorization-repair-item/${authItem.id}`);
                if (!authItem) {
                    continue;
                }
                const costLine = new CostLine();
                const foundItem = items.find(i => i.id === authItem.id);
                costLine.authorizationRepairItemSelector = foundItem;
                if (foundItem) {
                    costLine.amount = (foundItem.contractorCost ?? foundItem.priceRangeMin) ?? 0;
                    if (!costLine.amount && foundItem.priceRangeMax && foundItem.priceRangeDeltaPct) {
                        costLine.amount = foundItem.priceRangeMax - (foundItem.priceRangeMax * foundItem.priceRangeDeltaPct) - 1;
                    }
                    costLine.qty = 1;
                    costLine.defaultItem = true;
                    costLine.description = foundItem.name;
                    costLine.id = UtilitiesService.newid();
                    costLine.repairItemId = foundItem.id;
                    costLine.automaticallyAdded = true;
                    costLine.addedByStepId = inputParams.currentCell.id;
                    costLine.addedByFunctionInstanceId = inputParams.currentRunner.instanceId;
                    costLine.laborAmount = foundItem.laborOnlyAmount;

                    if (authItem.coveredType === 'Always') {
                        costLine.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'covered-by-default';
                    }

                    costLines.push(costLine);
                    if (!foundItem.companyNeverProvides) {
                        if (!entity.partsOrderingType || entity.partsOrderingType !== 'Never') {
                            costLine.companyProvidesPart = true;
                        }
                    }
                }
                if (!costLine.authorizationRepairItemSelector && authItem.coveredType === 'Always') {
                    costLine.authorizationRepairItemSelector = new WorkOrderLineAuthorizationRepairItem();
                    costLine.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'covered-by-default';
                }
            }
            let destination = inputParams.inputs[2];
            if (!destination || !destination.push) {
                destination = inputParams.objectInScope?.costLines;
            }
            if (destination) {
                const linesToRemove = destination.filter(i => i.addedByStepId === inputParams.currentCell.id);
                for (const line of linesToRemove) {
                    destination.splice(destination.indexOf(line), 1);
                }
                for (const line of costLines) {
                    destination.push(line);
                }
            }
            result.result = costLines;
        }

        return result;

    }
    type = typeMap.createAuthoItem;
}

export class CopyClipboardFunction implements IStepExecutor {
    type = typeMap.clipboardCopy;
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const input = inputParams.inputs[0];

        navigator.clipboard.writeText(input);

        const result = new ExecutorOutputParams();
        result.result = true;

        return result;
    }
}

export class CloseObjectDetailFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const result = new ExecutorOutputParams();
        if (inputParams.currentRunner.closeObjectDetail) {
            inputParams.currentRunner.closeObjectDetail(inputParams.currentRunner.objectInScope);
            result.result = true;
        } else {
            result.result = false;
        }

        return result;
    }

    type = typeMap.closeObjectDetail;
}

export class ChangeLane implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const result = new ExecutorOutputParams();
        if (inputParams.currentRunner.changeLanes) {
            inputParams.currentRunner.changeLanes(inputParams.currentRunner.objectInScope);
            result.result = true;
        } else {
            result.result = false;
        }

        return result;
    }
    type = typeMap.changeLane;
}

export class TransferToRepairTypeQuestionsFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        return new Promise(async (resolve, reject) => {
            const authoArgs: AuthorizationInputArgs = inputParams.objectInScope;
            const repairType = authoArgs.repairType;
            if (!repairType) {
                console.error('No repair type selected');
                return;
            }
            let processJSON = authoArgs.repairType.functionDefinition;
            let objectInScope = null;
            const runInNode: boolean = inputParams.inputs[3];

            if (!objectInScope) {
                objectInScope = inputParams.objectInScope;
            }

            const executor = new FunctionRunner(inputParams.currentRunner.executors,
                inputParams.currentRunner.dependencies,
                objectInScope, (results, final) => {
                    if (final) {

                        const args: AuthorizationInputArgs = executor.objectInScope;
                        const stepIds = executor.executedSteps.map(i => i.id);
                        const linesToEvaluate = args.costLines.filter(i => i.addedByStepId && i.addedByStepId !== 'initial' && i.addedByStepId !== 'very-initial' && i.addedByFunctionInstanceId === executor.instanceId);

                        for (const line of linesToEvaluate) {
                            const index = stepIds.indexOf(line.addedByStepId);
                            if (index === -1) {
                                args.costLines.splice(args.costLines.indexOf(line), 1);
                            }
                        }

                        const result = new ExecutorOutputParams();
                        result.result = executor;
                    }
                }, inputParams.currentRunner.executionContext);

            executor.showCustomForm = inputParams.currentRunner.showCustomForm;
            executor.showActivityIndicator = inputParams.currentRunner.showActivityIndicator;
            executor.questionRenderer = inputParams.currentRunner.questionRenderer;
            executor.setupFunctionQuestionRender = inputParams.currentRunner.setupFunctionQuestionRender;
            executor.parent = inputParams.currentRunner;
            executor.envVariables = inputParams.currentRunner.envVariables;
            executor.getSingleton = getSingletonFunction();
            executor.label = 'transferred-repair';
            executor.transferOriginatingStepId = inputParams.currentCell.id;

            let root = executor.parent;
            while (true) {

                if (!root.parent) {
                    const instancesToRemove = root.childInstanceIds.filter(i => i.spawnedByStepId === inputParams.currentCell.id);
                    for (const instance of instancesToRemove) {
                        root.childInstanceIds.splice(root.childInstanceIds.indexOf(instance), 1);
                    }
                    root.childInstanceIds.push({ instanceId: executor.instanceId, spawnedByStepId: inputParams.currentCell.id });
                    break;
                }
                root = root.parent;
            }

            //const cells = JSON.parse(processJSON);
            if (inputParams.currentRunner.onTransfer) {
                inputParams.currentRunner.onTransfer(executor);
                executor.onTransfer = inputParams.currentRunner.onTransfer;
            }


            //processJSON = JSON.stringify({ cells });
            if (inputParams.currentRunner.transferRunner) {
                inputParams.currentRunner.transferRunner(executor);
            }


            executor.runProcess(processJSON);


        });
    }
    type = typeMap.transferToRepairType;
}

export class TransferFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        return new Promise(async (resolve, reject) => {
            let processJSON = inputParams.inputs[0];
            let objectInScope = inputParams.inputs[1];
            const runInNode: boolean = inputParams.inputs[3];

            if (!objectInScope) {
                objectInScope = inputParams.objectInScope;
            }



            if (!processJSON && inputParams.inputs[2]) {
                const functionRetriever = inputParams.dependencies.functionRetriever;
                const customFunction: CustomFunction = await functionRetriever(inputParams.inputs[2].id);
                const parsed = JSON.parse(customFunction.functionJson);

                processJSON = JSON.stringify(parsed.cells);
            }

            if (runInNode) {
                const workflowInstance = new WorkflowInstance();
                workflowInstance.process = processJSON;
                workflowInstance.id = UtilitiesService.newid();
                workflowInstance.nextExecutedStepTime = inputParams.inputs[6];
                if (!workflowInstance.nextExecutedStepTime) {
                    workflowInstance.nextExecutedStepTime = new Date();
                }

                workflowInstance.environmentJson = JSON.stringify({
                    objectInScope,
                    envVariables: inputParams.currentRunner.envVariables,

                });
                workflowInstance.objectId = inputParams.inputs[4];
                workflowInstance.objectType = inputParams.inputs[5];
                workflowInstance.executionEnvironment = 'node';
                const oneMonth = new Date();
                oneMonth.setMonth(oneMonth.getMonth() + 1);
                workflowInstance.expirationDate = oneMonth;

                if (inputParams.inputs[2]) {
                    workflowInstance.workflowId = inputParams.inputs[2].id;
                } else {
                    workflowInstance.workflowId = '00000000-0000-0000-0000-000000000000';
                }
                workflowInstance.type = inputParams.currentRunner.envVariables['function-type'];
                const api: ApiService = inputParams.dependencies.api;
                await api.postSingleNode(`WorkflowInstance`, workflowInstance);
                const result = new ExecutorOutputParams();
                result.result = true;
                resolve(result);

            } else {

                const executor = new FunctionRunner(inputParams.currentRunner.executors,
                    inputParams.currentRunner.dependencies,
                    objectInScope, (results, final) => {
                        if (final) {
                            const result = new ExecutorOutputParams();
                            result.result = executor;
                            resolve(result);
                        }
                    }, inputParams.currentRunner.executionContext);

                executor.showCustomForm = inputParams.currentRunner.showCustomForm;
                executor.showActivityIndicator = inputParams.currentRunner.showActivityIndicator;
                executor.questionRenderer = inputParams.currentRunner.questionRenderer;
                executor.setupFunctionQuestionRender = inputParams.currentRunner.setupFunctionQuestionRender;
                executor.parent = inputParams.currentRunner;
                executor.envVariables = inputParams.currentRunner.envVariables;
                executor.getSingleton = getSingletonFunction();
                executor.label = 'transfered';
                executor.transferOriginatingStepId = inputParams.currentCell.id;
                const cells = JSON.parse(processJSON);
                if (inputParams.currentRunner.onTransfer) {
                    inputParams.currentRunner.onTransfer(executor);
                    executor.onTransfer = inputParams.currentRunner.onTransfer;
                }


                processJSON = JSON.stringify({ cells });
                if (inputParams.currentRunner.transferRunner) {
                    inputParams.currentRunner.transferRunner(executor);
                }
                executor.runProcess(processJSON);

            }
        });
    }
    type = typeMap.transfer;
}

export class ChangeJobFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const workOrderId = inputParams.inputs[0];
        const workOrderStatusId = inputParams.inputs[1];
        const workOrderLineId = inputParams.inputs[2];

        const api: ApiService = inputParams.dependencies.api;

        await api.patchNode('work-order/update-work-order-status', { id: workOrderId, workOrderStatusId, workOrderLineId });

        const result = new ExecutorOutputParams();
        result.result = true;
        return result;
    }
    type = typeMap.changeJobStatus;
}


export class CreateTaskFunctionAngular implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const task = new Task();
        task.sort = 0;
        task.id = UtilitiesService.newid();
        task.description = inputParams.inputs[2];
        task.title = inputParams.inputs[6];

        if (inputParams.inputs[1])
            task.taskQueueId = inputParams.inputs[1];

        switch (inputParams.inputs[4]) {
            case 'Policy':
                task.policyId = inputParams.inputs[3];
                break;
            case 'WorkOrder':
                task.workOrderId = inputParams.inputs[3];
                break;
            case 'Entity':
                task.entityId = inputParams.inputs[3];
                break;
        }
        if (inputParams.inputs[5])
            task.followUpDate = inputParams.inputs[5];

        const api: ApiService = inputParams.dependencies.api;

        console.log(inputParams.inputs[0]);
        if (inputParams.inputs[0]) {
            task.ownerId = inputParams.inputs[0];
        }
        if (!task.ownerId) {
            const loggedInUser = await api.getSingleDotNet<Entity>("Login/LoggedInEntity", null, () => new Entity());
            task.ownerId = loggedInUser.id;
        }

        await api.postSingleNode(`Task`, task);
        const output = new ExecutorOutputParams();
        output.result = task;
        return output;
    }
    type = typeMap.createTask;
}

export class RemoveFromQueueFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const output = new ExecutorOutputParams();
        if (inputParams.currentRunner.removeFromQueue) {

            output.result = true;
            inputParams.currentRunner.removeFromQueue(inputParams.objectInScope);
        } else {
            output.result = false;
        }

        return output;
    }

    type = typeMap.removeFromQueue;
}

export class CustomComponentFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        return new Promise(async (resolve, reject) => {
            const lazy: LazyComponentProviderService = inputParams.dependencies.lazy;
            const dialog: MatDialog = inputParams.dependencies.matDialog;

            const component = await lazy.getDynamicComponent(inputParams.inputs[0]);

            const objectInScope = inputParams.inputs[1] ? inputParams.inputs[1] : inputParams.currentRunner.objectInScope;
            const ref = dialog.open(component, { data: objectInScope });
            ref.afterClosed().subscribe(dialogResult => {
                const result = new ExecutorOutputParams();

                result.result = dialogResult ? dialogResult : 'Cancelled';
                resolve(result);
            });
        });
    }
    type = typeMap.customComponent;
}

export class EmailFunctionAngular implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {

        const api: ApiService = inputParams.dependencies.api;
        const objectId = inputParams.inputs[7];
        const objectType = inputParams.inputs[8];
        let attachments: MessageAttachment[] = inputParams.inputs[9];
        if (typeof attachments == 'string') {
            try {
                attachments = JSON.parse(attachments);
            } catch { }
        }

        let singleton = null;



        if (attachments && Array.isArray(attachments) && inputParams.objectInScope) {
            for (const attachment of attachments) {
                if (attachment.fileUrl) {
                    attachment.fileUrl = UtilitiesService.replaceValuesInStringFromObject(attachment.fileUrl, inputParams.objectInScope);
                    if (attachment.fileUrl.indexOf('{{singleton') > -1) {
                        if (!singleton) {
                            singleton = await api.getSingleNode('common/singleton');
                        }
                        if (singleton) {
                            attachment.fileUrl = UtilitiesService.replaceValuesInStringFromObject(attachment.fileUrl, singleton, 'singleton');
                        }
                    }
                }
                if (attachment.fileName) {
                    attachment.fileName = UtilitiesService.replaceValuesInStringFromObject(attachment.fileName, inputParams.objectInScope);
                    if (attachment.fileName.indexOf('{{singleton') > -1) {
                        if (!singleton) {
                            singleton = await api.getSingleNode('common/singleton');
                        }
                        if (singleton) {
                            attachment.fileName = UtilitiesService.replaceValuesInStringFromObject(attachment.fileName, singleton, 'singleton');
                        }
                    }
                }
            }
        }

        await api.postSingleNode(`document/email`, {
            to: inputParams.inputs[0],
            fromAddress: inputParams.inputs[1],
            subject: inputParams.inputs[2],
            message: inputParams.inputs[3],
            cc: inputParams.inputs[4],
            bcc: inputParams.inputs[5],
            xsmtpapi: inputParams.inputs[6],
            policyId: objectType === "Policy" ? objectId : null,
            workOrderId: objectType === "WorkOrder" ? objectId : null,
            entityId: objectType === "Entity" ? objectId : null,
            maintenanceServiceCustomerPropertyId: objectType === 'MaintenanceServiceCustomerProperty' ? objectId : null,
            maintenanceServiceCustomerPropertyServiceId: objectType === 'MaintenanceServiceCustomerPropertyService' ? objectId : null,
            saveAsNote: objectId ? true : false,
            attachments: attachments,
        });
        const output = new ExecutorOutputParams();

        return output;
    }
    type = typeMap.sendEmail;
}

export class AddTagsFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;
        await api.postSingleNode(`tag/add`, {
            objectId: inputParams.inputs[0],
            objectType: inputParams.inputs[1],
            tags: inputParams.inputs[2],
            expirationDate: inputParams.inputs[3],
        });
        const output = new ExecutorOutputParams();
        return output;
    }
    type = typeMap.addTags;
}

export class RemoveTagsFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;
        await api.postSingleNode(`tag/remove`, {
            objectId: inputParams.inputs[0],
            objectType: inputParams.inputs[1],
            tags: inputParams.inputs[2],
        });
        const output = new ExecutorOutputParams();
        return output;
    }
    type = typeMap.removeTags;
}

export class SaveObjectFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        let object = inputParams.inputs[0];
        let objectType = inputParams.inputs[1];

        if (!object) {
            object = inputParams.objectInScope;
        }

        const api: ApiService = inputParams.dependencies.api;
        await api.postNode(objectType, object);
        const result = new ExecutorOutputParams();
        result.result = true;

        return result;

    }
    type = typeMap.saveObject;
}

export class HasTagsFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;
        const output = new ExecutorOutputParams();
        output.result = await api.postSingleNode(`tag/has-tags`, {
            objectId: inputParams.inputs[0],
            objectType: inputParams.inputs[1],
            tags: inputParams.inputs[2],
            matchAny: inputParams.inputs[3],
        });
        output.result = output.result ? 'True' : 'False';


        return output;
    }
    type = typeMap.hasTags;
}

export class WaitFunction implements IStepExecutor {
    executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {

        const seconds = parseInt(inputParams.inputs[0]);

        // TODO: if seconds greater than some amount, then save it to DB to be picked up by a later process
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const output = new ExecutorOutputParams();
                output.result = true;
                resolve(output);
            }, seconds * 1000);
        });
    }
    type = typeMap.wait;
}

export class StopConnectContactFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;

        const contactId = inputParams.inputs[0];

        await api.patchNode(`amazonconnect/stop-contact/${contactId}`, null);
        const result = new ExecutorOutputParams();


        return result;
    }
    type = typeMap.stopConnectContact;
}

export class ConnectTaskFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;
        const contactFlowId = inputParams.inputs[0];
        const name = inputParams.inputs[1];
        const description = inputParams.inputs[2];
        const url = inputParams.inputs[3];

        const result = await api.postSingleNode(`AmazonConnect/create-task`, {
            name,
            description,
            contactFlowId,
            actions: [url]
        });

        const output = new ExecutorOutputParams();
        output.result = result;

        return output;
    }
    type = typeMap.createConnectTask;
}

export class AutoCallFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;

        const phoneNumber = inputParams.inputs[0];
        const message = inputParams.inputs[1];

        await api.postSingleNode(`Twilio/call?number=${phoneNumber}`, { message })
        const output = new ExecutorOutputParams();

        return output;
    }
    type = typeMap.autoPhoneCall;
}

export class CreateNoteFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const note = new Note();
        note.noteText = inputParams.inputs[0];
        note.entityId = inputParams.inputs[1];
        note.policyId = inputParams.inputs[2];
        note.workOrderId = inputParams.inputs[3];
        note.taskId = inputParams.inputs[4];
        note.showOnContractorPortal = inputParams.inputs[6];
        note.pinned = inputParams.inputs[7]
        note.isPrivate = false;
        note.id = UtilitiesService.newid();
        note.maintenanceServiceCustomerPropertyId = inputParams.inputs[8];
        note.maintenanceServiceCustomerPropertyServiceId = inputParams.inputs[9];
        const attachments = inputParams.inputs[5];
        if (!note.showOnContractorPortal) {
            note.showOnContractorPortal = false;
        }

        if (!note.pinned) {
            note.pinned = false
        }

        if (!note.entityId) {
            delete note.entityId;
        }
        if (!note.policyId) {
            delete note.policyId;
        }
        if (!note.workOrderId) {
            delete note.workOrderId;
        }
        if (!note.taskId) {
            delete note.taskId;
        }
        if (!note.maintenanceServiceCustomerPropertyId) {
            delete note.maintenanceServiceCustomerPropertyId;
        }
        if (!note.maintenanceServiceCustomerPropertyServiceId) {
            delete note.maintenanceServiceCustomerPropertyServiceId;
        }

        const api: ApiService = inputParams.dependencies.api;
        await api.postSingleNode(`common/note`, note);

        if (attachments && Array.isArray(attachments)) {
            for (const a of attachments) {
                const attachment: WorkOrderAttachmentModel = a;

                attachment.noteId = note.id;
                await api.postVoidNode(`note/attachment/${note.id}`, attachment);
            }
        }

        const result = new ExecutorOutputParams();
        result.result = note;
        return result;
    }
    type = typeMap.note;
}

export class RESTFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;

        const url = inputParams.inputs[0];
        const method = inputParams.inputs[1];
        let body = inputParams.inputs[2];
        if (typeof body === 'string') {
            try {
                body = JSON.parse(body);
            } catch { }
        }
        let useNode: boolean = inputParams.inputs[3];
        const extractionType = inputParams.inputs[4];


        let result: any;
        let urlCopy = url.slice();
        if (urlCopy.indexOf('http:') > -1 || urlCopy.indexOf('https:') > -1) {
            urlCopy = `REST?url=${encodeURI(url)}`;
            useNode = false;
        }

        switch (method) {
            case 'GET':
                result = useNode ? (await api.getArrayNode(urlCopy)) : (await api.getArrayDotNet(url));
                if (extractionType === 'Single') {
                    result = result[0];
                }
                break;
            case 'POST':
                switch (extractionType) {
                    case 'Single':

                        result = useNode ? (await api.postSingleNode(urlCopy, body)) : (await api.postSingleDotNet(url, body));
                        break;
                    case 'None':
                        result = useNode ? (await api.postNode(urlCopy, body, null, null, false, ResultExtractionType.None)) : (await api.postDotNet(urlCopy, body, null, null, false, ResultExtractionType.None));
                        break;
                    default:
                        result = useNode ? (await api.postArrayNode(urlCopy, body)) : (await api.postArrayDotNet(urlCopy, body));
                        break;
                }
                //result = useNode ? (await api.postArray2(url, body)) : (await api.postArray(url, body));
                break;
            case 'PATCH':
                result = useNode ? (await api.patchNode(urlCopy, body)) : (await api.patchDotNet(urlCopy, body));
                break;
            case 'PUT':
                result = useNode ? (await api.putNode(urlCopy, body)) : (await api.putDotNet(urlCopy, body));
                break;
            case 'DELETE':
                result = useNode ? (await api.deleteNode(urlCopy)) : (await api.deleteDotNet(urlCopy));
                break;

        }
        const output = new ExecutorOutputParams();

        output.result = result;

        return output;
    }
    type = typeMap.rest;
}

export class BroadcastApplicationMessageFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        // const api: ApiService = inputParams.dependencies.api;
        const missionService: MissionService = inputParams.dependencies.missionService;

        const applicationKey = inputParams.inputs[0];
        const message = inputParams.inputs[1];
        const shouldContinue = inputParams[2] === 'True' ? missionService.getSubject<boolean>() : null;
        const from = missionService.getFrom();

        missionService.raiseEvent(new EventArguments('COMMAND_ACTION_EVENT', {
            messageKey: applicationKey,
            messageBody: message,
            shouldContinue
        }));

        if (shouldContinue) {
            await from(shouldContinue);
        }

        const output = new ExecutorOutputParams();
        return output;

    }
    type = typeMap.broadcastApplicationMessage;
}

export class RefreshFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const refreshAction: () => any = inputParams.dependencies.refreshAction;
        if (refreshAction) {
            inputParams.objectInScope = await refreshAction();
        }
        const result = new ExecutorOutputParams();
        result.result = inputParams.objectInScope;
        return result;
    }
    type = typeMap.refresh;
}



export class RefreshAllFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const refreshAllAction: () => any = inputParams.dependencies.refreshAllAction;
        if (refreshAllAction) {
            inputParams.objectInScope = await refreshAllAction();
        }
        const result = new ExecutorOutputParams();
        result.result = inputParams.objectInScope;
        return result;
    }
    type = typeMap.refreshAll;
}

export class DialNumberFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const missionService: MissionService = inputParams.dependencies.missionService;

        let phoneNumber = inputParams.inputs[0];
        missionService.dialPhoneNumber(phoneNumber);

        const output = new ExecutorOutputParams();
        return output;
    }
    type = typeMap.dialNumber;
}

export class WeatherForecastFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;

        let postalCode = inputParams.inputs[0];
        const forecast = await api.getArrayNode(`weather/${postalCode}`);
        const output = new ExecutorOutputParams();
        output.result = forecast;

        return output;
    }
    type = typeMap.weatherForecast;
}

export class RunWorkflowFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;

        const workflowId = inputParams.inputs[0];
        const objectType = inputParams.inputs[1];
        const objectId = inputParams.inputs[2];

        api.postSingleDotNet(`WorkflowInstance`, {
            id: UtilitiesService.newid(),
            workflowId,
            objectId,
            objectType
        });

        const output = new ExecutorOutputParams();
        return output;
    }
    type = typeMap.runWorkflow;
}

export class SendCogentNotification implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;
        const employees: string = inputParams.inputs[0];
        const message = inputParams.inputs[1];
        const endpointUrl = inputParams.inputs[2];
        if (employees?.indexOf(',') > -1) {
            const employessArray = employees.split(',');
            const notifications: UserNotification[] = [];
            for (const employeeId of employessArray) {
                const notification = new UserNotification();
                notification.url = endpointUrl;
                notification.id = UtilitiesService.newid();
                notification.userId = employeeId;
                notification.description = message;
                await api.postSingleDotNet(`UserNotification`, notification);
                notifications.push(notification);
            }
            const output = new ExecutorOutputParams();
            output.result = notifications;

            return output;
        } else {
            const notification = new UserNotification();
            notification.url = endpointUrl;
            notification.id = UtilitiesService.newid();
            notification.userId = employees;
            notification.description = message;
            await api.postSingleDotNet(`UserNotification`, notification);
            const output = new ExecutorOutputParams();
            output.result = notification;
            return output;
        }
    }
    type = typeMap.sendCogentNotification;
}

export class AssignWorkOrderFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const employees: string = inputParams.inputs[0];
        const workOrderId = inputParams.inputs[1];
        const employeesSplit = employees.split(',');
        // const dc = new CogentDataContext();
        // const workOrder = await dc.getAsync(WorkOrder, workOrderId);
        // workOrder.assignedToUserId = employeesSplit[0];

        // await dc.executeTransactionAsync(async t => {
        //     await t.updateAsync(workOrder);
        // });

        const api: ApiService = inputParams.dependencies.api;
        await api.patchNode(`work-order/assign-job/${workOrderId}/${employeesSplit[0]}`, null);

        const output = new ExecutorOutputParams();
        return output;
    }
    type = typeMap.assignJob;
}

export class SendSMSNotificationFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;
        const recipients = inputParams.inputs[0];
        const message = inputParams.inputs[1];
        const sendToEntityId = inputParams.inputs[2];
        const overrideSendPreferences: boolean = inputParams.inputs[3];
        const from: string = inputParams.inputs[4];
        const objectId: string = inputParams.inputs[5];
        const objectType: string = inputParams.inputs[6];

        const args = new SmsComposeArgs();

        if (objectType == "Policy") {
            args.policyId = objectId;
        } else if (objectType == "WorkOrder") {
            args.workOrderId = objectId;
        }

        const saveNote = (args.policyId || args.workOrderId) as any;

        args.message = message;
        args.to = recipients;
        args.sendToEntityId = sendToEntityId;
        args.overrideSendPreferences = overrideSendPreferences;
        args.from = from;
        args.saveNote = saveNote;

        await api.postSingleNode(`users/SMS/send-message`, args);

        const output = new ExecutorOutputParams();
        output.result = args;
        return output;
    }
    type = typeMap.sendSMSNotification;
}

export class ChatFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        if (!inputParams.currentRunner.questionRenderer && inputParams.currentRunner.setupFunctionQuestionRender) {
            await inputParams.currentRunner.setupFunctionQuestionRender(inputParams.currentRunner);
        }

        if (inputParams.currentRunner.questionRenderer) {
            const args = new QuestionRenderInputArgs();
            args.functionCell = inputParams.currentCell;
            const contactFlowId = inputParams.inputs[2];



            inputParams.currentRunner.questionRenderer.startChat({ contactFlowId, inputParams });
            const output = new ExecutorOutputParams();
            output.quit = true;
            output.result = {};

            return output;

        }
    }
    type = typeMap.startChat;
}

abstract class QuestionFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {

        if (!inputParams.currentRunner.questionRenderer && inputParams.currentRunner.setupFunctionQuestionRender) {
            await inputParams.currentRunner.setupFunctionQuestionRender(inputParams.currentRunner);
        }
        let renderer: IQuestionRenderer = inputParams.currentRunner.questionRenderer;
        let currentRunner = inputParams.currentRunner;
        while (!renderer) {
            currentRunner = currentRunner.parent;
            if (currentRunner) {
                renderer = currentRunner.questionRenderer;
            } else {
                break;
            }
        }

        if (renderer) {
            if (inputParams.currentRunner.questionRenderer !== renderer) {
                inputParams.currentRunner.questionRenderer = renderer;
            }
            const args = new QuestionRenderInputArgs();
            args.functionCell = inputParams.currentCell;
            args.inputs = inputParams;
            const requiredInPort = args.functionCell.ports.items.find(i => i.group === 'in' && i.attrs.label.text === 'Required');
            if (requiredInPort) {
                const index = args.functionCell.ports.items.filter(i => i.group === 'in').indexOf(requiredInPort);
                if (index > -1) {
                    args.required = inputParams.inputs[index] === true;
                }
            }
            inputParams.currentRunner.questionRenderer.renderQuestion(args);
            const output = new ExecutorOutputParams();
            output.quit = true;
            output.result = {};

            return output;
        }
    }
    abstract type: string;
}

export class SingleItemSelectFunction extends QuestionFunction {
    type = typeMap.singleSelect;
}

export class AuthoItemAttributeFunction extends QuestionFunction {
    type = typeMap.authorizationRepairItemAttribute;
}

export class TagSelectionFunction extends QuestionFunction {
    type = typeMap.tagEntry;
}

export class ShowMessageFunction extends QuestionFunction {
    type = typeMap.showMessage;
}

export class ShowLottieFileFunction extends QuestionFunction {
    type = typeMap.lottieFile;
}

export class ShowImage extends QuestionFunction {
    type = typeMap.showImage;
}

export class TimeSlotSelection extends QuestionFunction {
    type = typeMap.preferredTimeSlot;
}

export class PlayYouTube extends QuestionFunction {
    type = typeMap.youTube;
}

export class PlanSelectionFunction extends QuestionFunction {
    type = typeMap.planSelection;
}

export class WorkOrderItemTypeSelectionFunction extends QuestionFunction {
    type = typeMap.workOrderItemTypeSelection;
}

export class RepairTypeSelectionFunction extends QuestionFunction {
    type = typeMap.selectRepairType;
}

// export class AIChatFunction extends QuestionFunction {
//     type = typeMap.aiChat;
// }

export class ChangeRepairTypeSelectionFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {

        const ouptput = new ExecutorOutputParams();
        let repairType: RepairType = inputParams.inputs[0][0];
        const authoInput: AuthorizationInputArgs = inputParams.objectInScope;
        const api: ApiService = inputParams.dependencies.api;

        repairType = await api.getSingleNode(`RepairType/${repairType.id}`, null, () => new RepairType());
        if (authoInput) {
            authoInput.repairType = repairType;
        }
        if (!authoInput.authorizationWarnings) {
            authoInput.authorizationWarnings = [];
        }

        const items = await api.getArrayNode(`authorization/authorization-repair-items-by-line/${authoInput.workOrderLine.id}`, null, () => new WorkOrderLineAuthorizationRepairItem()); // await this.authoApi.getWorkOrderLineAuthorizationRepairItemsByLineId(authoInput.workOrderLine.id);

        if (authoInput?.costLines) {
            const linesToRemove = authoInput.costLines.filter(i => i.addedByStepId === 'initial');
            for (const line of linesToRemove) {
                authoInput.costLines.splice(authoInput.costLines.indexOf(line), 1);
            }
        }
        const costLines: CostLine[] = [];

        for (const authItem of repairType.repairItems) {
            const costLine = new CostLine();
            const foundItem = items.find(i => i.id === authItem.id);
            costLine.authorizationRepairItemSelector = foundItem;
            if (foundItem) {
                costLine.amount = (foundItem.contractorCost ?? foundItem.priceRangeMin) ?? 0;
                costLine.qty = 1;
                costLine.defaultItem = true;
                costLine.description = foundItem.name;
                costLine.id = UtilitiesService.newid();
                costLine.repairItemId = foundItem.id;
                costLine.automaticallyAdded = true;
                costLine.addedByStepId = 'initial';
                costLine.laborAmount = foundItem.laborOnlyAmount;
                costLine.authorizationRepairItemGroupId = foundItem.authorizationRepairItemGroupId;
                if (repairType.coveredType === 'Always' || authItem.coveredType === 'Always') {
                    costLine.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'covered-by-default';
                }
                costLines.push(costLine);
                if (!foundItem.companyNeverProvides) {
                    if (foundItem.companyPrefersToProvide || authoInput.costLines.find(i => i.companyProvidesPart)) {
                        costLine.companyProvidesPart = true;
                        for (const existingLine of costLines) {
                            if (!existingLine.companyProvidesPart && !existingLine.authorizationRepairItemSelector?.companyNeverProvides) {
                                existingLine.companyProvidesPart = true;
                            }
                        }
                    } else {
                        const companyProvided = costLines.find(i => i.companyProvidesPart);
                        if (companyProvided) {
                            costLine.companyProvidesPart = true;
                        }
                    }
                }
            } else {
                console.error('could not find item:');
                console.error(authItem)
            }
        }

        if (!authoInput.costLines) {
            authoInput.costLines = [];
        }

        if (repairType.laborHours) {

            const costLine = new CostLine();
            costLine.amount = repairType.calcLaborAsHourlyRate ? authoInput.laborRate : repairType.laborHours;
            costLine.qty = repairType.calcLaborAsHourlyRate ? repairType.laborHours : 1;
            costLine.defaultItem = true;
            costLine.calcLaborAsHourlyRate = repairType.calcLaborAsHourlyRate;
            costLine.description = 'Labor';
            costLine.id = UtilitiesService.newid();
            costLine.repairItemId = 'labor';
            costLine.automaticallyAdded = true;
            costLine.allowQuantityEntry = true;
            costLine.addedByStepId = 'initial';
            costLine.companyProvidedAvailable = false;
            costLines.push(costLine);
            costLine.companyProvidesPart = false;
            costLine.authorizationRepairItemSelector = new WorkOrderLineAuthorizationRepairItem();
            costLine.authorizationRepairItemSelector.companyNeverProvides = true;
            costLine.authorizationRepairItemSelector.id = 'labor';
            costLine.authorizationRepairItemSelector.priceRangeMax = costLine.amount;
            costLine.authorizationRepairItemSelector.name = 'Labor';
            costLine.authorizationRepairItemSelector.companyNeverProvides = true;
            costLine.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'Labor';
        }

        for (const costLine of costLines) {
            authoInput.costLines.push(costLine);
        }
        const guidelinePrice = await api.getSingleNode(`authorization/guideline-price/${authoInput.workOrderLine.id}/${repairType.id}`, null, () => new RepairTypeGuidelinePrice());// await this.authoApi.getGuidelinePriceForRepairType(authoInput.workOrderLine.id, repairType.id);

        if (guidelinePrice) {
            if (guidelinePrice.price) {
                const trigger = new AuthorizationReviewTrigger();
                trigger.id = UtilitiesService.newid();
                trigger.type = 'TOTAL_PARTS_AND_AUTHO';
                trigger.value = guidelinePrice.price;
                trigger.description = 'Over guideline price';
                trigger.stepId = 'initial';
                authoInput.authorizationWarnings.push(trigger);
            }
            if (guidelinePrice.outOfPocket) {
                const trigger = new AuthorizationReviewTrigger();
                trigger.description = 'Over out of pocket guideline';
                trigger.id = UtilitiesService.newid();
                trigger.stepId = 'initial';
                trigger.value = guidelinePrice.outOfPocket;
                authoInput.authorizationWarnings.push(trigger);
            }
        }
        if (repairType.alwaysWarn) {
            const trigger = new AuthorizationReviewTrigger();
            trigger.type = 'MANUAL_REVIEW';
            trigger.description = repairType.alwaysWarnMessage;
            trigger.id = UtilitiesService.newid();
            trigger.stepId = 'initial';
            authoInput.authorizationWarnings.push(trigger);
        }

        if (repairType.warnIfDefaultItemsChange) {
            const trigger = new AuthorizationReviewTrigger();
            trigger.type = 'DEFAULT_PARTS_ALTERED';
            trigger.id = UtilitiesService.newid();
            trigger.stepId = 'initial';
            authoInput.authorizationWarnings.push(trigger);
        }
        if (authoInput.salesItemCoverageWorkOrderItem && repairType.coveredType === 'Conditional') {
            if (authoInput.salesItemCoverageWorkOrderItem.coveredRepairTypes.indexOf(repairType.id) === -1) {
                const trigger = new AuthorizationReviewTrigger();
                trigger.description = `Repair type: ${repairType.name} is not covered`;
                trigger.type = 'MANUAL_REVIEW';
                trigger.id = UtilitiesService.newid();
                trigger.stepId = 'initial';
                authoInput.authorizationWarnings.push(trigger);
            }
        } else if (repairType.coveredType === 'Never') {
            const trigger = new AuthorizationReviewTrigger();
            trigger.description = `Repair type: ${repairType.name} is not covered`;
            trigger.type = 'MANUAL_REVIEW';
            trigger.id = UtilitiesService.newid();
            trigger.stepId = 'initial';
            authoInput.authorizationWarnings.push(trigger);
        }

        if (inputParams.inputs[1]) {
            const transferFunction = new TransferToRepairTypeQuestionsFunction();
            await transferFunction.executeStep(inputParams);
        }

        return ouptput;

    }
    type = typeMap.changeRepairType;

}

export class AIChatFunction implements IStepExecutor {

    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        if (!inputParams.currentRunner.questionRenderer && inputParams.currentRunner.setupFunctionQuestionRender) {
            await inputParams.currentRunner.setupFunctionQuestionRender(inputParams.currentRunner);
        }

        if (inputParams.currentRunner.questionRenderer) {
            const args = new QuestionRenderInputArgs();
            args.functionCell = inputParams.currentCell;
            const prompt = inputParams.inputs[0];



            inputParams.currentRunner.questionRenderer.startChat({
                inputParams, isAI: true, prompt, intentPrompt: inputParams.inputs[2], aiMessages: [
                    { role: 'system', content: prompt }
                ]
            });
            const output = new ExecutorOutputParams();
            output.quit = true;
            output.result = {};

            return output;

        }
    }
    type = typeMap.aiChat;
}

export class CheckListFunction extends QuestionFunction {
    type = typeMap.checkList;
}

export class MultilineTextBoxFunction extends QuestionFunction {
    type = typeMap.multiLineTextBox;
}

export class TextBoxFunction extends QuestionFunction {
    type = typeMap.textBox;
}

export class AuthoItemSelectionFunction extends QuestionFunction {
    type = typeMap.authoItemSelection
}

export class NumberBoxFunction extends QuestionFunction {
    type = typeMap.numberBox;
}

export class StarRatingFunction extends QuestionFunction {
    type = typeMap.starRating;
}

export class RepairItemFunction extends QuestionFunction {
    type = typeMap.repairItem;
}

export class BrandFunction extends QuestionFunction {
    type = typeMap.brand;
}

export class AddressEntryFunction extends QuestionFunction {
    type = typeMap.addressEntry;
}

export class DateBoxFunction extends QuestionFunction {
    type = typeMap.dateBox;
}

export class UploadImageFunction extends QuestionFunction {
    type = typeMap.uploadImage;
}

export class RichTextBoxFunction extends QuestionFunction {
    type = typeMap.richText;
}

export class FileUploadFunction extends QuestionFunction {
    type = typeMap.fileUpload;
}

export class CheckCircleAnimatedFunction extends QuestionFunction {
    type = typeMap.checkCircleAnimated;
}

export class SliderFunction extends QuestionFunction {
    type = typeMap.slide;
}

export class EmailBoxFunction extends QuestionFunction {
    type = typeMap.emailBox;
}

export class FindPolicyFunction extends QuestionFunction {
    type = typeMap.findPolicy;
}

export class GetJobItemSelectionFunction extends QuestionFunction {
    type = typeMap.getJobItem;
}

export class HyperlinkFunction extends QuestionFunction {
    type = typeMap.hyperlink;
}

export class ShowJobItemQuestionsFunction extends QuestionFunction {
    type = typeMap.jobItemQuestions;
}

export class GetUserPoliciesFunction extends QuestionFunction {
    type = typeMap.getPolicies;
}

export class ConfirmJobContact extends QuestionFunction {
    type = typeMap.confirmContact;
}

export class PhoneBoxFunction extends QuestionFunction {
    type = typeMap.phoneBox;
}

export class CheckBoxFunction extends QuestionFunction {
    type = typeMap.checkBox;
}

export class PhoneLinkFunction extends QuestionFunction {
    type = typeMap.phoneLink;
}

export class HeadingFunction extends QuestionFunction {
    type = typeMap.heading;
}

export class SeparatorFunction extends QuestionFunction {
    type = typeMap.separator;
}

export class ConfirmButtonFunction extends QuestionFunction {
    type = typeMap.confirmButton;
}

export class GetCreditCardsFunction extends QuestionFunction {
    type = typeMap.getCreditCards;
}

export class ShowAlertDialogFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const dialogService: DialogsService = inputParams.dependencies.dialogService;

        const subject = inputParams.inputs[0];
        const message = inputParams.inputs[1];

        await dialogService.alert(subject, message).toPromise();

        const output = new ExecutorOutputParams();
        output.result = true;
        return output;

    }
    type = typeMap.alertDialog;
}

export class NavigateFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const url: string = inputParams.inputs[0];
        const target = inputParams.inputs[1];

        if (url) {
            if (target === '_blank') {
                window.open(url);
            } else {
                if (!url.startsWith('http')) {
                    const router: Router = inputParams.dependencies.router;
                    router.navigateByUrl(url);
                } else {
                    window.location.href = url;
                }
            }
        }

        const output = new ExecutorOutputParams();
        output.result = true;

        return output;
    }
    type = typeMap.navigateToUrl;
}

export class ShowConfirmDialog implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const subject = inputParams.inputs[0];
        const message = inputParams.inputs[1];
        const dialogService: DialogsService = inputParams.dependencies.dialogService;

        const dialogResult = await dialogService.confirm(subject, message).toPromise();
        const output = new ExecutorOutputParams();
        output.result = dialogResult ? 'True' : 'False';

        return output;
    }
    type = typeMap.confirmDialog;
}

export class ShowUndoFunction implements IStepExecutor {
    timeout;
    executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {

        return new Promise((resolve, reject) => {
            const missionService: MissionService = inputParams.dependencies.missionService;

            const message = inputParams.inputs[0];
            missionService.showUndo(new UndoArguments(() => {

                clearTimeout(this.timeout);

                const output = new ExecutorOutputParams();
                output.result = 'Undo';

                resolve(output);
            }, message, 5000));

            this.timeout = setTimeout(() => {
                const output = new ExecutorOutputParams();
                output.result = 'Default';

                resolve(output);
            }, 5001)
        });


    }
    type = typeMap.undo;
}

export class SendSlyDialFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;
        const recipient = inputParams.inputs[0];
        const messageId = inputParams.inputs[1];
        const slyBroadcastMessage = new SlyBroadcastMessage();
        slyBroadcastMessage.audioRecordingName = messageId;
        slyBroadcastMessage.phoneNumbers = recipient.split(',');

        await api.postSingleNode('SlyBroadcast', slyBroadcastMessage);
        const output = new ExecutorOutputParams();
        output.result = slyBroadcastMessage;
        return output;
    }
    type = typeMap.slyDial;
}

export class HasRepairItemFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const api: ApiService = inputParams.dependencies.api;
        const policyId = inputParams.inputs[1];
        const repairItemId = inputParams.inputs[0];

        const repairItems = await api.getArrayNode(`policy/repair-items/${policyId}`);
        const foundItem = repairItems.find(i => i.id.toLowerCase() === repairItemId.toLowerCase());

        const output = new ExecutorOutputParams();
        output.result = foundItem ? 'True' : 'False';

        return output;
    }
    type = typeMap.hasRepairItem;
}

export class ShowToastSuccessFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const message = inputParams.inputs[0];
        const missionService: MissionService = inputParams.dependencies.missionService;
        missionService.showSuccessToast(message);
        const output = new ExecutorOutputParams();
        output.result = true;

        return output;
    }
    type = typeMap.toastSuccessMessage;
}

export class ShowToastFunction implements IStepExecutor {
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        const message = inputParams.inputs[0];
        const missionService: MissionService = inputParams.dependencies.missionService;
        missionService.showToast(message);
        const output = new ExecutorOutputParams();
        output.result = true;

        return output;
    }
    type = typeMap.toastMessage;
}

export class BindTemplateFunction implements IStepExecutor {

    private static doSqrlRender(layoutFileText: string, data: any, iterationCount = 0) {
        if (!layoutFileText) {
            return '';
        }

        try {
            layoutFileText = Sqrl.render(layoutFileText, data, { useWith: true });
        } catch (e: any) {
            const msg: string = e.message;
            if (msg && msg.indexOf(' is not defined') > -1) {
                const objKey = msg.replace(' is not defined', '')
                data[objKey] = '';

                layoutFileText = this.doSqrlRender(layoutFileText, data, iterationCount++);
            } else {
                console.error(e);

            }
        }

        return layoutFileText;

    }
    constructor() {
        Sqrl.helpers.define("evaluate", function (content, block, config) {
            var data = content.params[0];
            var func = content.params[1];

            return func(data);
        });
        Sqrl.filters.define("currency", function (num) {
            return "$" + parseFloat(num).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        });

        Sqrl.filters.define('shortDate', function (dt: Date) {
            if (!dt.getFullYear) {
                dt = new Date(dt);
            }
            return (dt.getMonth() + 1) + '/' + dt.getDate() + '/' + dt.getFullYear();
        });

        Sqrl.filters.define("phone", function (phoneNumber: string) {
            if (!phoneNumber) return "";
            return UtilitiesService.formatPhoneNumber(phoneNumber);
        });

        Sqrl.helpers.define("evaluate", function (content, block, config) {
            var data = content.params[0];
            var func = content.params[1];

            return func(data);
        });
    }
    async executeStep(inputParams: ExecutorInputParams): Promise<ExecutorOutputParams> {
        let template = inputParams.inputs[0];
        let bindingObject = inputParams.inputs[1];
        if (!bindingObject) {
            bindingObject = inputParams.objectInScope;
        }
        const output = new ExecutorOutputParams();

        let templateObject = inputParams.inputs[2];

        let hasTemplate = false;
        if (templateObject && !Array.isArray(templateObject)) {
            hasTemplate = true;
        } else if (templateObject && templateObject.length) {
            hasTemplate = true;
        }


        if (hasTemplate) {
            if (Array.isArray(templateObject)) {
                templateObject = templateObject[0];
            }
            const api: ApiService = inputParams.dependencies.api;
            const subjectMessage = await api.postSingleNode("template/" + templateObject.id, bindingObject);
            if (inputParams.inputs[4]) {
                output.result = subjectMessage;
            } else {
                output.result = subjectMessage.message;
            }
        } else {
            if (template?.indexOf('{{singleton') > -1 && !bindingObject!.singleton) {
                const api: ApiService = inputParams.dependencies.api;
                const singleton = await api.getSingleNode(`common/singleton`);
                if (!bindingObject) {
                    bindingObject = {};

                }
                bindingObject.singleton = singleton;
            }
            if (!template) {
                template = '';
            }
            output.result = BindTemplateFunction.doSqrlRender(template, bindingObject, 0);// Sqrl.render(template, bindingObject, { useWith: true });
        }

        return output;
    }
    type = typeMap.stringTemplateBinding;
}
