import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AuthorizationRepairItemSelectorComponent } from '@cogent/client/shared/components/autho/authorization-repair-item-selector/authorization-repair-item-selector.component';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { trigger, transition, style, animate } from '@angular/animations';
import { AuthorizationRepairItemGroupLimit, AuthorizationRepairItemSummary, AuthorizationReviewTrigger, CostLine, FavorFundExpenseSummary, WorkOrderLineAuthorizationRepairItem } from '@upkeeplabs/models/cogent';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { GetItemAmountDialogComponent } from '@cogent/client/shared/components/autho/get-item-amount-dialog/get-item-amount-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { FunctionQuestionRendererModule } from '../../functions/function-question-renderer/function-question-renderer.module';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { WorkOrderItemTypeDiagramViewerComponent } from '../work-order-item-type-diagram-viewer/work-order-item-type-diagram-viewer.component';
import { InfoCallOutDirective } from '@cogent/client/shared/directives/info-call-out.directive';

@Component({
    selector: 'app-autho-detail-entry',
    standalone: true,
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [
            transition(':enter', [
                style({ opacity: 0, height: '0' }),
                animate('200ms', style({ height: '*' })),
                animate('100ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1, height: '*' }),
                animate('100ms', style({ opacity: 0 })),
                animate('200ms', style({ height: '0' }))
            ])
        ])
    ],
    imports: [CommonModule,
        MatButtonModule,
        MatIconModule,
        AuthorizationRepairItemSelectorComponent,
        DisplayTotalModule,
        ReactiveFormsModule,
        GetItemAmountDialogComponent,
        FormsModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        UpkeepPipesModule,
        MatCheckboxModule,
        MatMenuModule,
        MatRippleModule,
        MatFormFieldModule,
        InfoCallOutDirective,
        MatSlideToggleModule,
        WorkOrderItemTypeDiagramViewerComponent,
        MatInputModule],
    templateUrl: './autho-detail-entry.component.html',
    styleUrl: './autho-detail-entry.component.scss'
})
export class AuthoDetailEntryComponent implements OnDestroy, OnChanges {

    @Input() items: WorkOrderLineAuthorizationRepairItem[];
    @Input() costLines: CostLine[] = [];
    @Output() costLinesChange: EventEmitter<CostLine[]> = new EventEmitter();
    @Input() sideBarOpen = false;
    @Output() sideBarOpenChange: EventEmitter<boolean> = new EventEmitter();
    @Input() hasIncompatibilityCoverage = false;
    @Output() authorizationPushed: EventEmitter<boolean> = new EventEmitter();
    @Input() perOccurrenceLimit: number;
    @Input() authorizing: boolean;
    @Input() commonOutOfPockets: AuthorizationRepairItemSummary[];
    groups: CostLineGroup[];
    @Input() warnings: AuthorizationReviewTrigger[];
    @Input() internalView: boolean = false;
    @Input() favorFundExpenses: FavorFundExpenseSummary[];
    @Input() authorizationRepairItemGroupLimits: AuthorizationRepairItemGroupLimit[];
    @Input() workOrder: WorkOrderSummaryClient;
    @Input() laborRate: number;
    @Input() laborHours: number;
    @Input() workOrderItemTypeId: string;
    @Input() disabled: boolean;

    editHours = false;
    hours = [.5, 1, 1.5, 2, 2.5, 3];
    diagramOpen = false;


    speechToTextResult = '';
    commonOOPOpen = false;
    gettingAttributes = false;

    @ViewChild('repairItemSelectorComponent') repairItemSelectorComponent: AuthorizationRepairItemSelectorComponent;

    selectedCostLine: CostLine;

    constructor(private snackBar: MatSnackBar,
        private missionService: MissionService,
        private matDialog: MatDialog,
        private zone: NgZone) {

    }
    ngOnChanges(changes: SimpleChanges): void {
        this.updateOOPValues();
        this.groupCostLines();
        if (changes.items && changes.items.currentValue) {
            this.items = this.items.sort((a, b) => a.name > b.name ? 1 : -1);
        }
    }
    ngOnDestroy(): void {
        try {
            if (this.recognition) {
                this.recognition.stop();
                delete this.recognition;
            }
        } catch { }
    }

    closeDiagramViewer() {
        this.diagramOpen = false;
    }

    itemsSelectedFromDiagram(items: AuthorizationRepairItemSummary[]) {
        this.diagramOpen = false;

        for (const item of items) {
            const costLine = new CostLine();
            costLine.authorizationRepairItemSelector = this.items.find(i => i.id === item.id);
            costLine.description = item.name;
            costLine.amount = item.priceRangeMax;
            costLine.id = UtilitiesService.newid();
            costLine.allowQuantityEntry = item.allowQuantityEntry;

            this.costLines.push(costLine);
        }
        this.groupCostLines();
    }

    swallowClick(evt: MouseEvent) {
        evt.stopPropagation();
    }


    showCostLinePriceIndicator(costLine: CostLine) {
        return costLine.authorizationRepairItemSelector?.priceRangeMax && costLine.authorizationRepairItemSelector.priceRangeDeltaPct && !costLine.companyProvidesPart;
    }

    getCostLinePct(costLine: CostLine) {
        let value = costLine.amount;
        let yellowEnd = costLine.authorizationRepairItemSelector?.priceRangeMax;
        let redBegin = yellowEnd + .01;
        let deltaPct = costLine.authorizationRepairItemSelector.priceRangeDeltaPct;
        let redEnd = yellowEnd * (1 + deltaPct);
        let yellowBegin = redBegin - (redBegin * deltaPct);
        let greenEnd = yellowBegin - .01;
        let greenBegin = greenEnd - (yellowEnd * deltaPct);

        if (value < greenBegin) {
            value = greenBegin;
        }
        if (value > redEnd) {
            value = redEnd;
        }

        value -= greenBegin;
        const total = redEnd - greenBegin;

        return value / total;
    }

    getCostLinePctDisplay(costLine: CostLine) {
        return (this.getCostLinePct(costLine) * 100) + '%';
    }

    getArrowColor(costLine: CostLine) {
        let value = costLine.amount;
        let yellowEnd = costLine.authorizationRepairItemSelector?.priceRangeMax;
        let redBegin = yellowEnd + .01;
        let deltaPct = costLine.authorizationRepairItemSelector.priceRangeDeltaPct;
        let yellowBegin = redBegin - (redBegin * deltaPct);
        if (value < yellowBegin) {
            return 'rgb(0, 255, 0)';
        }
        if (value >= yellowBegin && value < redBegin) {
            return 'rgb(255, 255, 0)';
        }
        return 'rgb(255, 0, 0)';
    }


    get showPriceIndicator() {
        return this.yellowEnd && this.deltaPct && this.internalView;
    }

    get redEnd() {
        return this.yellowEnd * (1 + this.deltaPct);
    }

    get redBegin() {
        return this.yellowEnd + .01;
    }

    get yellowEnd() {
        if (!this.selectedCostLine?.authorizationRepairItemSelector) {
            return 0;
        }
        return this.selectedCostLine.authorizationRepairItemSelector.priceRangeMax;
    }

    get arrowColorCss() {
        const value = this.selectedCostLine.amount;
        if (value <= this.greenEnd) {
            return 'rgba(0, 255, 0, 1)';
        } else if (value >= this.redBegin) {
            return 'rgba(255, 0, 0, 1)';
        }
        return 'rgba(255, 255, 0, 1)';
    }

    get deltaPct() {
        if (!this.selectedCostLine?.authorizationRepairItemSelector) {
            return 0;
        }
        return this.selectedCostLine.authorizationRepairItemSelector.priceRangeDeltaPct;
    }

    get yellowBegin() {
        return this.redBegin - (this.redBegin * this.deltaPct);
    }

    get greenEnd() {
        return this.yellowBegin - .01;
    }

    get greenBegin() {
        return this.greenEnd - (this.yellowEnd * this.deltaPct);
    }

    get barPct() {
        let value = this.selectedCostLine.amount;

        if (value < this.greenBegin) {
            value = this.greenBegin;
        }
        if (value > this.redEnd) {
            value = this.redEnd;
        }

        value -= this.greenBegin;
        const total = this.redEnd - this.greenBegin;

        return value / total;
    }

    get barPctDisplay() {
        return (this.barPct * 100) + '%';
    }

    saveAuthorization() {
        this.authorizationPushed.emit(true)
    }

    get isDenial() {
        if (!this.internalView) {
            return false;
        }

        const count = this.warnings?.filter(i => !i.override).length;
        return count ? true : false;
    }

    get hasAuthorizationLines() {

        return this.costLines?.length > 0;
    }

    get filteredOutOfPocketItems() {
        if (!this.commonOutOfPockets) {
            return null;
        }
        if (!this.costLines) {
            return this.commonOutOfPockets;
        }
        const ids = this.costLines.filter(i => i.repairItemId).map(i => i.repairItemId);

        return this.commonOutOfPockets.filter(i => ids.indexOf(i.id) === -1);
    }

    updateOOPValues() {
        if (!this.items || !this.commonOutOfPockets) {
            return;
        }

        for (const oop of this.commonOutOfPockets) {
            const item = this.items.find(i => i.id === oop.id);
            oop.name = item.name;
        }
    }

    get favorFundTotal() {
        if (!this.favorFundExpenses || this.favorFundExpenses.length === 0) {
            return 0;
        }

        return this.favorFundExpenses.map(i => i.amount).reduce((a, b) => a + b, 0);
    }


    createNew() {
        this.selectedCostLine = new CostLine();
        this.selectedCostLine.id = UtilitiesService.newid();
        this.sideBarOpen = !this.sideBarOpen;
        this.sideBarOpenChange.emit(this.sideBarOpen);
        this.selectedCostLine.companyProvidedAvailable = true;
        this.selectedCostLine.companyProvidesPart = false;
    }

    addCommonOutOfPocket(repairItem: AuthorizationRepairItemSummary) {

        const foundItem = this.items.find(i => i.id === repairItem.id);
        if (foundItem) {
            repairItem.priceRangeMin = foundItem?.priceRangeMin;
            const ref = this.matDialog.open(GetItemAmountDialogComponent, { data: repairItem, autoFocus: false });
            ref.afterClosed().subscribe(result => {
                if (result || result === 0) {
                    const costLine = new CostLine();
                    costLine.authorizationRepairItemSelector = foundItem;
                    if (foundItem) {
                        costLine.amount = result;
                        costLine.qty = 1;
                        costLine.defaultItem = true;
                        costLine.description = foundItem.name;
                        costLine.id = UtilitiesService.newid();
                        costLine.repairItemId = foundItem.id;
                        costLine.automaticallyAdded = true;
                        costLine.addedByStepId = 'initial';
                        costLine.companyProvidesPart = false;
                        this.costLines.push(costLine);

                        this.missionService.showSuccessToast('Item Added');
                        this.groupCostLines();
                    }
                }
            });
        }

    }

    editLine(item: CostLine) {
        const copy: CostLine = UtilitiesService.copyObject(item, null, () => new CostLine());
        this.selectedCostLine = copy;
        copy.defaultItem = true;
        if (copy.authorizationRepairItemSelector) {
            copy.allowQuantityEntry = copy.authorizationRepairItemSelector.allowQuantityEntry;
        } else {
            copy.allowQuantityEntry = true;
        }
        this.sideBarOpen = true;
        this.sideBarOpenChange.emit(this.sideBarOpen);
    }

    repairItemNameChange(name: string) {
        const line: CostLine = this.selectedCostLine;
        delete line.repairItemId;
        delete line.authorizationRepairItemSelector;
        line.description = name;
        line.allowQuantityEntry = true;
        if (this.costLines.find(i => i !== this.selectedCostLine && i.companyProvidesPart)) {
            line.companyProvidesPart = true;
        } else {
            line.companyProvidesPart = false;
        }
    }

    getIsLineCovered(item: CostLine) {
        if (item.authorizationRepairItemSelector && !item.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId) {
            return false;
        }
        if (!item.forIncompatibility && item.authorizationRepairItemSelector) {
            return item.isCovered;
        }


        return this.hasIncompatibilityCoverage;
    }

    unDefault() {
        this.selectedCostLine.defaultItem = false;
        delete this.selectedCostLine.authorizationRepairItemSelector;
        delete this.selectedCostLine.description;

        setTimeout(() => this.repairItemSelectorComponent.focus(), 400);
    }

    listening = false;
    recognition: any;
    recognize() {
        if (!this.recognition) {
            const recognitionSvc = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
            this.recognition = new recognitionSvc();
            this.recognition.continuous = true;
            this.recognition.onresult = (e) => {
                const accumulatedResult = [];
                for (const result of e.results) { accumulatedResult.push(`${result[0].transcript}`); }
                this.zone.run(() => {
                    this.selectedCostLine.disputeReason = accumulatedResult.join(' ');
                })
            }
            this.recognition.start();
        } else {
            this.recognition.stop();
            delete this.recognition;
        }
    }

    attributesComplete(repairItem: WorkOrderLineAuthorizationRepairItem) {
        this.selectedCostLine.selectedAttributes = repairItem.selectedAttributes;
    }

    repairItemSelected(repairItem: WorkOrderLineAuthorizationRepairItem) {
        const line = this.selectedCostLine;
        line.repairItemId = repairItem.id;
        line.description = repairItem.name;
        line.authorizationRepairItemSelector = repairItem;
        line.allowQuantityEntry = repairItem.allowQuantityEntry;
        line.authorizationRepairItemGroupId = repairItem.authorizationRepairItemGroupId;
        line.laborAmount = repairItem.laborOnlyAmount;
        line.qty = 1;
        if (repairItem.priceRangeMin) {
            line.amount = repairItem.priceRangeMin;
        }
        if (repairItem.contractorCost) {
            line.amount = repairItem.contractorCost;
        }
        if (!line.amount && repairItem.priceRangeMax && repairItem.priceRangeDeltaPct) {
            line.amount = repairItem.priceRangeMax - (repairItem.priceRangeMax * repairItem.priceRangeDeltaPct) - 1;
        }
        if (repairItem?.companyNeverProvides) {
            line.companyProvidesPart = false;
        }

        line.companyProvidedAvailable = !repairItem?.companyNeverProvides;

        line.selectedAttributes = repairItem.selectedAttributes;
        if (repairItem.companyPrefersToProvide || this.costLines.find(i => i !== this.selectedCostLine && i.companyProvidesPart)) {
            line.companyProvidesPart = true;
        } else {
            line.companyProvidesPart = false;
        }
        if (repairItem.companyNeverProvides) {
            line.companyProvidesPart = false;
        }
    }

    showCostLinePriceRange(costLine: CostLine) {

    }

    get canSaveItem() {
        return this.selectedCostLine && this.selectedCostLine.description && !this.gettingAttributes;
    }

    approveOOP(item: CostLine, evt: MouseEvent) {
        evt.stopPropagation();
        item.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'manually-approved';
        this.groupCostLines();
    }

    markAsOutOfPocket(item: CostLine) {
        delete item.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId;
        this.groupCostLines();
    }

    groupCostLines() {
        if (!this.costLines) {
            return;
        }
        const result: CostLineGroup[] = [];
        for (const line of this.costLines) {
            let group = '';
            if (line.isQuestion) {
                group = 'Unknown';
            } else if (this.getIsLineCovered(line)) {
                group = 'Covered';
            } else {
                group = 'Out Of Pocket';
            }

            let groupItem = result.find(i => i.group === group);
            if (!groupItem) {
                groupItem = new CostLineGroup();
                groupItem.group = group;
                groupItem.costLines = [];
                result.push(groupItem);
            }

            groupItem.costLines.push(line);
        }

        this.groups = result;
    }

    addUpdateItem() {
        const index = this.costLines.indexOf(this.costLines.find(i => i.id === this.selectedCostLine.id));
        if (index > -1) {
            this.costLines.splice(index, 1);
            this.costLines.splice(index, 0, this.selectedCostLine);
            this.costLinesChange.emit(this.costLines);
        } else {
            this.costLines.push(this.selectedCostLine);
            this.costLinesChange.emit(this.costLines);
        }

        delete this.selectedCostLine;
        this.sideBarOpen = false;
        this.sideBarOpenChange.emit(this.sideBarOpen);
        this.groupCostLines();
    }


    get outOfPocketLabel() {
        return 'Out Of Pocket' + (this.amountGreaterThanLimit ? '*' : '');
    }

    get oop() {
        let amt = this.costLines.map(i => {
            if ((i.forIncompatibility && !this.hasIncompatibilityCoverage) || !i.authorizationRepairItemSelector || !i.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId) {
                return i.ext;
            }
            if (i.authorizationRepairItemSelector?.limitPerUnit && i.authorizationRepairItemSelector?.limitPerUnit < i.amount) {
                return (i.amount - i.authorizationRepairItemSelector.limitPerUnit) * i.qty;
            }
            return 0;
        }).reduce((a, b) => a + b, 0);

        const groupLimits = GroupedLimits.fromCostLines(this.costLines);
        for (const item of groupLimits) {
            amt += item.outOfPocket;
        }
        if (this.amountGreaterThanLimit) {
            amt += (this.approvedTotal + this.partsCost) - this.perOccurrenceLimit;
        }
        return amt;
    }

    get amountGreaterThanLimit() {
        return (this.approvedTotal + this.partsCost) > this.perOccurrenceLimit;
    }

    get approvedTotal() {

        let amt = this.costLines.filter(i =>
            (!i.forIncompatibility || this.hasIncompatibilityCoverage) && !i.companyProvidesPart && i.authorizationRepairItemSelector && i.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId)
            .map(i => {
                if (i.authorizationRepairItemSelector.limitPerUnit && i.authorizationRepairItemSelector.limitPerUnit < i.amount) {
                    return i.authorizationRepairItemSelector.limitPerUnit * i.qty;
                }
                return i.ext;
            }).reduce((a, b) => a + b, 0);
        amt += this.costLines.filter(i => i.companyProvidesPart && i.laborAmount).map(i => i.laborAmount).reduce((a, b) => a + b, 0);


        const groupLimits = GroupedLimits.fromCostLines(this.costLines.filter(i => !i.companyProvidesPart));

        for (const item of groupLimits) {
            amt -= item.outOfPocket;
        }

        return amt;
    }

    get diagnosisAmount(): number {
        return this.laborHours * this.laborRate;
    }
    isDiagnosis = false;

    get approvedTotalWithoutTrip() {
        this.isDiagnosis = false;
        const count = (this.warnings ?? []).filter(i => !i.override).length;
        const total = this.approvedTotal + this.favorFundTotal - this.authorizationGroupLimits;
        if (count > 0 || total < this.diagnosisAmount) {
            this.isDiagnosis = true;
            return this.diagnosisAmount;
        }

        return total;
    }

    get amtRequestedLabel() {
        return this.isDiagnosis ? 'Diagnosis Fee' : 'Amount Requested';
    }

    get tripCharge() {
        let tripCharge = 0;
        if (this.workOrder && this.workOrder.tripFeeAllowance && this.workOrder.lines.length > 0) {
            tripCharge = this.workOrder.tripFeeAllowance / this.workOrder.lines.length;
        }
        return tripCharge;
    }

    get approvedTotalFinal() {
        // if (!this.warnings) {
        //     return this.approvedTotal - this.authorizationGroupLimits;
        // }
        // const count = this.warnings.filter(i => !i.override).length;
        // if (count > 0) {
        //     return 0;
        // }
        // let tripCharge = 0;
        // if(this.workOrder && this.workOrder.tripFeeAllowance && this.workOrder.lines.length > 0) {
        //     tripCharge = this.workOrder.tripFeeAllowance / this.workOrder.lines.length;
        // }
        return this.approvedTotalWithoutTrip + this.tripCharge;
        // return this.approvedTotal + this.favorFundTotal - this.authorizationGroupLimits + tripCharge;
    }

    get authorizationGroupLimits() {
        if (!this.authorizationRepairItemGroupLimits) {
            return 0;
        }
        let groupLimitTotals: any = {};

        for (const line of this.costLines) {
            if (line.authorizationRepairItemGroupId) {
                if (!groupLimitTotals[line.authorizationRepairItemGroupId]) {
                    groupLimitTotals[line.authorizationRepairItemGroupId] = 0;
                }
                groupLimitTotals[line.authorizationRepairItemGroupId] += line.amount * line.qty;
            }
        }

        let totalGroupLimitOutOfPocket = 0;
        for (const group in groupLimitTotals) {
            const groupTotal = groupLimitTotals[group];
            const limit = this.authorizationRepairItemGroupLimits.find(i => i.id === group);
            if (limit && limit.limitPerOccurance < groupTotal) {
                totalGroupLimitOutOfPocket += (groupTotal - limit.limitPerOccurance);
            }
        }
        return totalGroupLimitOutOfPocket;
    }

    get oopFinal() {
        if (!this.warnings) {
            return this.oop + this.authorizationGroupLimits;
        }
        const count = this.warnings.filter(i => !i.override).length;
        if (count > 0) {
            return this.oop + this.approvedTotal + this.authorizationGroupLimits;
        }
        return this.oop - this.favorFundTotal + this.authorizationGroupLimits;
    }

    get partsCost() {
        let amt = this.costLines.filter(i =>
            (!i.forIncompatibility || this.hasIncompatibilityCoverage) && i.companyProvidesPart && i.authorizationRepairItemSelector && i.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId)
            .map(i => {
                if (i.authorizationRepairItemSelector.limitPerUnit && i.authorizationRepairItemSelector.limitPerUnit < i.amount) {
                    return i.authorizationRepairItemSelector.limitPerUnit * i.qty;
                }
                return i.ext;
            }).reduce((a, b) => a + b, 0);


        const groupLimits = GroupedLimits.fromCostLines(this.costLines);

        for (const item of groupLimits) {
            amt -= item.outOfPocket;
        }
        return amt;
    }

    get isRed() {
        return this.pct > 70;
    }


    get isGreen() {
        if (this.pct < 40) {
            return true;
        }

        return false;
    }

    get isYellow() {
        if (this.pct >= 40 && this.pct < 70) {
            return true;
        }
        return false;
    }

    get pct() {

        let value = this.costLines.filter(i => !i.companyProvidesPart && i.isCovered).map(i => i.amount).reduce((a, b) => a + b, 0);
        const minValue = this.costLines.filter(i => !i.companyProvidesPart && i.isCovered).map(i => i.authorizationRepairItemSelector.priceRangeMin).reduce((a, b) => a + b, 0) * .95;
        const maxValue = this.costLines.filter(i => !i.companyProvidesPart && i.isCovered).map(i => i.authorizationRepairItemSelector.priceRangeMax).reduce((a, b) => a + b, 0) * 1.1;


        if (value > maxValue) {
            value = maxValue;
        }
        if (value < minValue) {
            value = minValue;
        }

        let range = maxValue - minValue;
        value = value - minValue;

        if (range === 0) {
            return 0;
        }

        return (value / range) * 100;
    }

    get indicatorPercent() {
        return this.pct.toString() + '%';
    }


    deleteItem(item: CostLine, evt: MouseEvent) {
        evt.stopPropagation();
        const index = this.costLines.indexOf(item);

        this.costLines.splice(index, 1);
        this.groupCostLines();
        const ref = this.snackBar.open('Item Removed', 'Undo', { duration: 10000 }).onAction().subscribe(value => {
            this.costLines.splice(index, 0, item);
            this.groupCostLines();
        });
    }

}

class GroupedLimits {
    salesItemCoverageAuthorizationRepairItemId: string;
    limit: number;
    items: CostLine[];

    get coveredAmount() {
        const amt = this.items.map(i => i.ext).reduce((a, b) => a + b);
        if (amt > this.limit) {
            return this.limit;
        }

        return amt;
    }

    get outOfPocket() {
        const amt = this.items.map(i => i.ext).reduce((a, b) => a + b);
        if (amt > this.limit) {
            return amt - this.limit;
        }
        return 0;
    }

    static fromCostLines(costLines: CostLine[]) {
        const groups: GroupedLimits[] = [];
        for (const item of costLines.filter(i => i.authorizationRepairItemSelector?.limitPerClaim)) {
            let group = groups.find(i => i.salesItemCoverageAuthorizationRepairItemId === item.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId);
            if (!group) {
                group = new GroupedLimits();
                group.salesItemCoverageAuthorizationRepairItemId = item.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId;
                group.limit = item.authorizationRepairItemSelector.limitPerClaim;
                group.items = [];
                groups.push(group);
            }

            group.items.push(item);
        }

        return groups;
    }
}

class CostLineGroup {
    group: string;
    costLines: CostLine[];
}