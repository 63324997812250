<div style="display: inline-block;">
    <div aflex>
        <a (click)="dialNumber()" style="display: inline-block">
            <div aflex>
              <div nogrow>
                <i class="material-icons" [class.large]="alwaysShowNumber && phoneNumberDescription" *ngIf="icon">
                  {{icon}}
                </i>
              </div>
              <div style="margin-left: 5px"> {{display}}
                  <div class="description" *ngIf="alwaysShowNumber && phoneNumberDescription">
                      {{phoneNumberDescription}}
                  </div>
              </div>
              
            </div>
        
          </a>
          <div nogrow *ngIf="showCopyButton">
            <button mat-icon-button (click)="copyToClipboard()" style="margin-top: -11px; margin-left: -20px;">
              <mat-icon>content_copy</mat-icon>
            </button>

          </div>
    </div>

</div>
<!-- <a (click)="dialNumber()" style="display: inline-block">
  <div aflex>
    <div nogrow>
      <i class="material-icons" [class.large]="alwaysShowNumber && phoneNumberDescription" *ngIf="icon">
        {{icon}}
      </i>
    </div>
    <div style="margin-left: 5px"> {{display}}
        <div class="description" *ngIf="alwaysShowNumber && phoneNumberDescription">
            {{phoneNumberDescription}}
        </div>
    </div>
    
  </div>

</a> -->
