import { Component, Input, SimpleChanges, Output, EventEmitter, OnChanges, AfterViewChecked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentApiService } from '@cogent/client/shared/services/api/document-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { CommandModel } from '@cogent/client/shared/models/common/command.model';
import { EmailArgs, EmailAddress, EmailAttachment } from '@cogent/shared/models/other/email-args.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { EmailComposeModalComponent } from '@cogent/client/shared/components/communication/email-compose-link/email-compose-modal/email-compose-modal.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { HeightDirectivesModule } from '@cogent/client/shared/directives/height-directives/height-directives.module';

declare var document: any;

@Component({
    selector: 'app-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.css'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, RouterModule, HeightDirectivesModule]
})
export class DocumentViewerComponent implements OnChanges, AfterViewChecked {

    @Input() htmlDocument: string;
    @Input() commands: CommandModel[];
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Input() defaultEmailAddress: string;
    @Input() defaultSubject: string;
    @Input() defaultEmailText: string;
    @Input() downloadFileName: string;
    @Input() workOrderId: string;
    @Input() taskId: string;
    @Input() policyId: string;
    @Output() emailSent: EventEmitter<any> = new EventEmitter<any>();
    @Input() hideEmail: boolean = false;
    @Input() convertHtmlDownloadToPdf = false;
    @Input() invoiceArgs: any;
    @Input() downloadFileUrl: string;

    emailBoxOpen = false;
    private contentSet = false;
    sending = false;


    get actionCommands(): CommandModel[] {
        if (!this.commands) {
            return null;
        }

        return this.commands.filter(i => i.action);
    }

    get routerCommands(): CommandModel[] {
        if (!this.commands) {
            return null;
        }

        return this.commands.filter(i => i.routerLink);
    }

    constructor(private documentApi: DocumentApiService, private missionService: MissionService, private dialog: MatDialog) {

    }

    print() {
        UtilitiesService.printArea(null, document.getElementById('iFrame').contentWindow.document.getElementsByTagName('html')[0], false);
    }

    closeDocumentPreview() {
        this.onClose.emit();
    }

    get isValidEmail(): boolean {
        return this.defaultSubject != null && this.defaultSubject !== '' && UtilitiesService.validateEmail(this.defaultEmailAddress);
    }

    async toggleEmailBox() {
        const args = new EmailArgs();
        args.subject = this.defaultSubject;
        args.message = this.defaultEmailText;
        if (this.defaultEmailAddress) {
            args.toAddresses = [new EmailAddress(this.defaultEmailAddress)];
        }
        const attachment = new EmailAttachment();

        attachment.contentType = 'text/html';
        attachment.fileContents = btoa(unescape(encodeURIComponent(this.htmlDocument)));
        attachment.fileName = this.downloadFileName ? this.downloadFileName : 'file.html';
        args.attachments = [attachment];

        const pdfAttachment = new EmailAttachment();
        pdfAttachment.contentType = "application/pdf";
        pdfAttachment.fileContents = await this.documentApi.convertToPdf(this.htmlDocument);
        pdfAttachment.fileName = attachment.fileName.replace('.html', '.pdf');
        args.attachments = [pdfAttachment];

        if (this.invoiceArgs) {
            args.message = this.invoiceArgs.emailInvoiceMessage;
        }
        this.dialog.open(EmailComposeModalComponent, { data: args });
    }

    sendViaEmail() {
        this.sending = true;
        let createNote = false;
        if (this.policyId || this.workOrderId) {
            createNote = true;
        }
        this.documentApi.sendHtmlDocument(this.defaultEmailAddress, this.defaultSubject,
            this.htmlDocument, this.workOrderId, this.taskId, this.policyId, createNote).then(() => {
                this.sending = false;
                this.emailBoxOpen = false;
                this.missionService.showSuccessToast('Message Sent');
                this.emailSent.emit();
            });
    }

    private download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    doDownload() {
        if(this.downloadFileUrl) {
            window.open(this.downloadFileUrl, '_blank');
            return;
        }
        if (this.convertHtmlDownloadToPdf) {

        } else {
            let downloadFileName = 'file.html';
            if (this.downloadFileName != null && this.downloadFileName !== '') {
                downloadFileName = this.downloadFileName;
            }
            this.download(downloadFileName, this.htmlDocument);
        }
    }

    async doDownloadPDF() {
        const filename = this.downloadFileName.replace('.html', '.pdf');
        console.log(filename);

        const text = await this.documentApi.convertToPdf(this.htmlDocument);

        const element = document.createElement('a');
        element.setAttribute('href', 'data:application/pdf;base64,' + text);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    private setDocumentContent() {
        if (this.contentSet) {
            return;
        }

        if (!document.getElementById('iFrame')) {
            return;
        }

        const iFrameDoc = document.getElementById('iFrame').contentWindow.document;
        iFrameDoc.open();
        iFrameDoc.write(this.htmlDocument);
        iFrameDoc.close();


        this.contentSet = true;
    }

    ngAfterViewChecked() {
        this.setDocumentContent();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.htmlDocument) {
            this.contentSet = false;
            this.setDocumentContent();
        }
    }
}
