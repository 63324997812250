<div *ngIf="customFormQuestions" [id]="formId">
    <div *ngFor="let question of customFormQuestions?.questions">

        <div *ngIf="question.type === typeMap.singleSelect">
            <ng-container *ngIf="getAnswers(question).length > 6">
                <mat-form-field appearance="outline">
                    <mat-label>{{getLabel(question)}}</mat-label>
                    <mat-select [(ngModel)]="currentRunner.outputValues[question.id]">
                        <mat-option *ngFor="let answer of getAnswers(question)" [value]="answer">
                            {{answer}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="getAnswers(question).length <= 6">
                <div class="data-label">{{getLabel(question)}}</div>

                <button *ngFor="let answer of getAnswers(question)"
                    (click)="currentRunner.outputValues[question.id] = answer" mat-button class="toggle-button offset"
                    [class.selected]="answer === currentRunner.outputValues[question.id]">
                    {{answer}}
                </button>
                <div bottommargin20></div>
            </ng-container>

        </div>
        <ng-container *ngIf="question.type === typeMap.searchChipSelection">
            <app-search-chip-selection [(selectedResults)]="currentRunner.outputValues[question.id]"
                [searchType]="getInputValue(question,'Type')" [searchSubType]="getInputValue(question,'SubType')"
                [placeholder]="getInputValue(question,'Label')" [singleSelect]="getInputValue(question,'Single Select')"
                [showWorkOrderView]="getInputValue(question,'Type')?.toLowerCase() === 'workorder'"
                [showPolicyView]="getInputValue(question,'SubType')?.toLowerCase() === 'policy'">

            </app-search-chip-selection>
        </ng-container>

        <div *ngIf="question.type === typeMap.checkList" bottommargin20>
            <div class="data-label">{{getLabel(question)}}</div>
            <!-- <pre>{{question | json}}</pre> -->
            <mat-checkbox *ngFor="let answer of question.custom?.answers" (ngModelChange)="updateListAnswers(question)"
                [(ngModel)]="question.custom.selectedItems[answer]">
                {{answer}}
            </mat-checkbox>
        </div>

        <div *ngIf="question.type === typeMap.addressEntry" bottommargin20>
            <app-single-address-entry [loadPropertyMeta]="true" [(address)]="currentRunner.outputValues[question.id]"></app-single-address-entry>
        </div>

        <div *ngIf="question.type === typeMap.multiLineTextBox">
            <mat-form-field appearance="outline">
                <mat-label>{{getLabel(question)}}</mat-label>
                <textarea matInput rows="5" [(ngModel)]="currentRunner.outputValues[question.id]"></textarea>
            </mat-form-field>
        </div>



        <div *ngIf="question.type === typeMap.textBox">
            <mat-form-field appearance="outline">
                <mat-label>{{getLabel(question)}}</mat-label>
                <input matInput [(ngModel)]="currentRunner.outputValues[question.id]">
            </mat-form-field>
        </div>

        <div *ngIf="question.type === typeMap.numberBox" style="max-width: 250px;">
            <mat-form-field appearance="outline">
                <mat-label>{{getLabel(question)}}</mat-label>
                <input matInput type="number" [(ngModel)]="currentRunner.outputValues[question.id]">
            </mat-form-field>
        </div>

        <div *ngIf="question.type === typeMap.brand">
            <mat-form-field appearance="outline">
                <mat-label>{{getLabel(question)}}</mat-label>
                <!-- <input matInput [(ngModel)]="currentRunner.outputValues[question.id]"> -->
                <mat-select [(ngModel)]="currentRunner.outputValues[question.id]">
                    <mat-option *ngFor="let brand of brands" [value]="brand.id">
                        {{brand.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="question.type === typeMap.dateBox">
            <mat-form-field appearance="outline" style="max-width: 300px">
                <mat-label>{{getLabel(question)}}</mat-label>
                <input matInput [matDatepicker]="picker" [min]="getInputValue(question,'Min')" [max]="getInputValue(question,'Max')" [(ngModel)]="currentRunner.outputValues[question.id]">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div *ngIf="question.type === typeMap.uploadImage">
            <app-swapable-image iconClass="image" [pasteTargetId]="formId" [prompt]="getLabel(question)"
                [showClear]="true" [(url)]="currentRunner.outputValues[question.id]"></app-swapable-image>
        </div>

        <div *ngIf="question.type === typeMap.emailBox">
            <mat-form-field appearance="outline">
                <mat-label>{{getLabel(question)}}</mat-label>
                <input matInput type="email" [(ngModel)]="currentRunner.outputValues[question.id]">
            </mat-form-field>
        </div>

        <div *ngIf="question.type === typeMap.phoneBox">
            <mat-form-field appearance="outline">
                <mat-label>{{getLabel(question)}}</mat-label>
                <input matInput type="tel" mask="(000) 000-0000" [(ngModel)]="currentRunner.outputValues[question.id]">
            </mat-form-field>
        </div>

        <div *ngIf="question.type === typeMap.checkBox">
            <mat-slide-toggle [(ngModel)]="currentRunner.outputValues[question.id]">{{getLabel(question)}}
            </mat-slide-toggle>
        </div>

        <div *ngIf="question.type === typeMap.showMessage">
            <div class="info" [innerHTML]="getLabel(question)"></div>
        </div>

        <div *ngIf="question.type === typeMap.heading">
            <h1>{{getLabel(question)}}</h1>
        </div>

        <div *ngIf="question.type === typeMap.separator">
            <hr>
        </div>

        <div style="max-width: 500px;" *ngIf="question.type === typeMap.tagEntry">
            <app-tag-chip-selection [(selectedTags)]="currentRunner.outputValues[question.id]"
                [viewWorkOrderTags]="getInputValue(question,'Job')"
                [viewPolicyTags]="getInputValue(question,'Subscription')"
                [viewEntityTags]="getInputValue(question,'Entity')" [viewTaskTags]="getInputValue(question,'Task')"
                [viewContractorInvoiceTags]="getInputValue(question,'SP Invoice')"
                [viewPurchaseOrderTags]="getInputValue(question,'PO')"
                [viewInvoiceTags]="getInputValue(question,'Invoice')" placeholder="{{getLabel(question)}}">

            </app-tag-chip-selection>
        </div>

        <div *ngIf="question.type === typeMap.preferredTimeSlot">
            <div class="data-label">{{getLabel(question)}}</div>
            <app-appointment-selection [selectedItemId]="getInputValue(question,'Selected Item Id')"
                [(selectedTimeSlots)]="currentRunner.outputValues[question.id]"></app-appointment-selection>
        </div>

        <div *ngIf="question.type === typeMap.slide" style="padding-left: 10px;">
            <div class="data-label">{{getLabel(question)}}</div>
            <mat-slider thumbLabel [displayWith]="formatLabel" [min]="getInputValue(question,'Min')" [discrete]="true"
                [max]="getInputValue(question,'Max')">
                <input [(ngModel)]="currentRunner.outputValues[question.id]" matSliderThumb #slider>
            </mat-slider>
        </div>

        <div *ngIf="question.type === typeMap.richText" bottommargin20 style="min-width: 600px;max-width: 600px">
            <div class="data-label" bottommargin10>{{getLabel(question)}}</div>
            <app-rich-text-and-snippet [pasteIntoDocument]="true" [disabled]="false" [quickNotes]="[]"
                [(ngModel)]="currentRunner.outputValues[question.id]"></app-rich-text-and-snippet>
        </div>
        <div *ngIf="question.type === typeMap.findPolicy">

            <app-search-chip-selection [showPolicyView]="true" searchType="Policy"
                [singleSelect]="getInputValue(question, 'Single Select')" searchSubType=""
                [placeholder]="getLabel(question)"
                [(selectedResults)]="currentRunner.outputValues[question.id]"></app-search-chip-selection>

        </div>

        <div *ngIf="question.type === typeMap.fileUpload">
            <div class="data-label">{{getLabel(question)}}</div>
            <app-file-upload [(files)]="currentRunner.outputValues[question.id]"
                [mimeType]="getInputValue(question,'Mime Type')">
            </app-file-upload>
        </div>
    </div>

    <div aflex topmargin20>
        <div></div>
        <div nogrow>
            <button mat-button mat-raised-button class="small-primary" [disabled]="!canSave" color="primary"
                (click)="save()">
                Save
            </button>
        </div>
    </div>



</div>