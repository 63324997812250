
import { ApiEndpoints } from '@cogent/shared/models/common/api-endpoints.model';
import { TaskSummary } from '@upkeeplabs/models/cogent';
export class TaskSummaryClient extends TaskSummary {
    tagsJson: string;
    hoursWorked: number;

    get createdByContactPhone() {
        if (this.createdByMobileNumber) {
            return this.createdByMobileNumber;
        }
        if (this.createdByHomeNumber) {
            return this.createdByHomeNumber;
        }
        if (this.createdByOfficeNumber) {
            return this.createdByOfficeNumber;
        }
        return null;
    }

    get ownerThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.ownerId}`;
    }

    get createdByThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.createdById}`;
    }

    get contractorThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/photo/${this.contractorId}`;
    }

    get workOrderItemThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}WorkOrderItem/${this.workOrderItemId}/Photo`;
    }

    get propertyThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}policy/${this.policyId}/StreetView`;
    }

    get slaStatus() {
        if (this.status === 'In Progress') {
            return 'GREEN';
        }
        if (this.status === 'Overdue') {
            return 'RED';
        }
        if (this.status === 'Due Today') {
            return 'YELLOW';
        }

        return 'NONE';
    }

    get timeOpen() {
        if (!this.createdDate) {
            return '';
        }

        let milliseconds = new Date().getTime() - this.createdDate.getTime();

        const days = Math.floor(milliseconds / 86400000);
        milliseconds = milliseconds - days * 86400000;
        const hours = Math.floor(milliseconds / 3600000);
        milliseconds = milliseconds - hours * 3600000;

        const minutes = Math.floor(milliseconds / 60000);

        let result = '';
        if (days > 0) {
            if (days === 1) {
                result = days + ' day ';
            } else {
                result = days + ' days ';
            }
        }
        if (hours > 0) {
            if (hours === 1) {
                result += hours + ' hour ';
            } else {
                result += hours + ' hours ';
            }
        }
        if (minutes > 0) {
            if (minutes === 1) {
                result += minutes + ' minute';
            } else {
                result += minutes + ' minutes';
            }
        }
        return result;
    }

    private _tags;
    get tags(): any[] {
        if (!this._tags) {
            if (this.tagsJson) {
                this._tags = JSON.parse(this.tagsJson);
            } else if (this.tagsJSON) {
                this._tags = JSON.parse(this.tagsJSON);
            } else {
                this._tags = [];
            }
        }

        return this._tags;
    }
    set tags(value: any[]) {
        this._tags = value;
        //this.tagsJson = JSON.stringify(value);
    }   

}
