import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CustomForm, FormItem } from '@cogent/client/shared/models/custom-form.model';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { Tag, UserTextSnippet } from '@upkeeplabs/models/cogent';
import { SearchResultModel } from '@cogent/shared/models/common/search-result.model';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AppointmentSelectionComponent } from '@cogent/client/shared/components/service/appointment-selection/appointment-selection.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { CustomTableChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/custom-table-chip-selection/custom-table-chip-selection.module';
import { SearchChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/search-chip-selection/search-chip-selection.module';
import { TagChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/tag-chip-selection/tag-chip-selection.module';
import { RichTextAndSnippetModule } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet.module';
import { SwapableImageModule } from '@cogent/client/shared/components/misc/swapable-image/swapable-image.module';
@Component({
    selector: 'app-custom-form-display',
    templateUrl: './custom-form-display.component.html',
    styleUrls: ['./custom-form-display.component.css'],
    standalone: true,
    providers: [MatDatepickerModule, MatNativeDateModule],
    imports: [
        CommonModule,
        MatButtonModule,
        DragDropModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        AppointmentSelectionComponent,
        MatRadioModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSliderModule,
        TagChipSelectionModule,
        FormsModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        SearchChipSelectionModule,
        CustomTableChipSelectionModule,
        DragDropModule,
        // DatePickerModule,
        // MaterialSharedModule,
        SwapableImageModule,
        RichTextAndSnippetModule
      ]
})
export class CustomFormDisplayComponent implements OnInit, OnChanges {

    @Input() selectedForm: CustomForm;
    @Output() selectedFormChange: EventEmitter<CustomForm> = new EventEmitter();
    @Input() previewMode: boolean;
    @Input() selectedFormItem: FormItem;
    @Output() selectedFormItemChange: EventEmitter<FormItem> = new EventEmitter();
    @Input() formComplete: boolean;
    @Output() formCompleteChange: EventEmitter<boolean> = new EventEmitter();


    selectedTags: Tag[];

    intellisenseWidth = 400;
    itemHeight = 36;
    intellisenseMaxHeight = 288;

    intellisenseOpen = false;
    intellisenseLeft = '';
    intellisenseTop = '';
    intellisenseField: any;
    intellisenseHeight = '';
    quickNotes: UserTextSnippet[];
    formId = UtilitiesService.newid();

    selectedQuickNote: UserTextSnippet;
    filteredQuickNotes: UserTextSnippet[];
    config = {
        toolbar: 'Basic',
        height: 215,
        toolbarGroups: [
            { name: 'clipboard', groups: ['clipboard', 'undo'] },
            { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
            { name: 'font' }]
    };

    constructor(private entityApi: EntityApiService) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.selectedForm && changes.selectedForm.currentValue) {
            this.raiseIsFormComplete();

            console.group(changes);

            if (changes.selectedForm.currentValue.formItems.find(i => i.useSnippets)) {
                this.entityApi.getUserTextSnippets().then(snippets => this.quickNotes = snippets);
            }
        }
    }

    handleFormItemResort(event: any) {
        // I'm not sure why this needed to be adjusted by -1
        moveItemInArray(this.selectedForm.formItems, event.previousIndex - 1, event.currentIndex - 1);
    }

    get intellisenseWidthPx() {
        return this.intellisenseWidth + 'px';
    }

    searchResultsChange(value: SearchResultModel[], formItem: FormItem) {
        if (value && value.length) {
            formItem.answer = value[0].id;
            this.raiseIsFormComplete();
        }
    }

    searchResultsChangeKey(value: any[], formItem: FormItem) {
        if (value && value.length) {
            formItem.answer = value[0].key;
            this.raiseIsFormComplete();
        }
    }

    intellisenseMaxHeightPx() {
        return this.intellisenseMaxHeight + 'px';
    }

    selectFormItem(formItem: FormItem) {
        this.selectedFormItem = formItem;
        this.selectedFormItemChange.emit(formItem);
    }

    getMaxWidth(formItem: FormItem) {
        if (formItem.maxWidth) {
            return formItem.maxWidth + 'px';
        }

        return 'unset';
    }

    setCheckboxAnswers(answer, formItem: FormItem) {
        setTimeout(() => {
            let selectedItems = '';
            for (const item of formItem.answers) {
                if (item.selected) {
                    if (selectedItems) {
                        selectedItems += ', ';
                    }
                    selectedItems += item.answer;
                }
            }

            formItem.answer = selectedItems;
            this.raiseIsFormComplete();
        });
    }

    selectedTagsChange(formItem: FormItem) {
        if (!this.selectedTags) {
            this.selectedTags = [];
        }

        formItem.answer = this.selectedTags.map(i => i.id).join(',');
    }

    respondToKeyDownEvent(event: KeyboardEvent, formItem: FormItem) {
        if (!formItem.useSnippets) {
            return;
        }
        const field = event.target;
        this.intellisenseField = field;
        if (event.key === 'Escape') {
            this.intellisenseOpen = false;
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
        } else if (event.key === 'ArrowDown' && this.intellisenseOpen) {
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();

            let index = this.filteredQuickNotes.indexOf(this.selectedQuickNote) + 1;
            if (index >= this.filteredQuickNotes.length) {
                index = 0;
            }
            this.selectedQuickNote = this.filteredQuickNotes[index];
            this.scrollElementIntoView();
        } else if (event.key === 'ArrowUp' && this.intellisenseOpen) {
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();

            let index = this.filteredQuickNotes.indexOf(this.selectedQuickNote) - 1;
            if (index === -1) {
                index = this.filteredQuickNotes.length - 1;
            }
            this.selectedQuickNote = this.filteredQuickNotes[index];
            this.scrollElementIntoView();
        } else if ((event.key === 'Tab' || event.key === 'Enter') && this.intellisenseOpen) {
            if (this.selectedQuickNote) {
                this.setIntellisenseField(this.selectedQuickNote);
            } else {
                this.intellisenseOpen = false;
            }
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private scrollElementIntoView() {
        if (!this.selectedQuickNote) {
            return;
        }
        const itemToScroll = document.getElementById(this.selectedQuickNote.key);

        if (!itemToScroll) {
            return;
        }

        itemToScroll.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    private getWordStartIndex() {
        if (!this.intellisenseField) {
            return -1;
        }
        const field = this.intellisenseField;
        const fieldValue: string = field.value;
        const selectionEnd = this.intellisenseField.selectionEnd;
        const beginning = fieldValue.substring(0, selectionEnd);
        let lastSpace = beginning.lastIndexOf(' ', selectionEnd);
        let lastEnter = beginning.lastIndexOf('\n', selectionEnd);

        if (lastSpace === -1 && lastEnter === -1) {
            return 0;
        }

        if (lastSpace > -1) {
            lastSpace++;
        }
        if (lastEnter > -1) {
            lastEnter++;
        }

        return lastSpace > lastEnter ? lastSpace : lastEnter;
    }


    private getWordEndIndex() {
        if (!this.intellisenseField) {
            return -1;
        }
        const field = this.intellisenseField;
        const fieldValue: string = field.value;
        const selectionEnd = this.intellisenseField.selectionEnd;
        let nextSpace = fieldValue.indexOf(' ', selectionEnd);
        let nextEnter = fieldValue.indexOf('\r', selectionEnd);

        if (nextSpace === -1) {
            nextSpace = selectionEnd;
        }
        if (nextEnter === -1) {
            nextEnter = selectionEnd;
        }

        return nextSpace < nextEnter ? nextSpace : nextEnter;
    }

    respondToKeyEvent(event: KeyboardEvent, formItem: FormItem) {
        if (!this.quickNotes) {
            return;
        }
        if (!formItem.useSnippets) {
            return;
        }

        const field: any = event.target;
        if (event.key === ' ' || event.key === '}') {
            this.intellisenseOpen = false;
        } else if ((event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Tab' || event.key === 'Enter') && this.intellisenseOpen) {
            return;
        } else if (event.key === 'Escape') {
            this.intellisenseOpen = false;
            event.cancelBubble = true;
            event.preventDefault();
            event.stopPropagation();
            return;
        } else if (event.key === 'Enter' || event.key === 'Tab') {
            return;
        }
        const fieldValue: string = field.value;

        const wordStartIndex = this.getWordStartIndex();
        const wordEndIndex = this.getWordEndIndex();
        const lastWord = fieldValue.substring(wordStartIndex, wordEndIndex).toLowerCase();

        if (lastWord.length > 2) {
            this.filteredQuickNotes = this.quickNotes
                .filter(i => (i.key.toLowerCase().indexOf(lastWord) > -1));

            if (this.filteredQuickNotes.length > 0) {
                this.intellisenseOpen = true;
            } else {
                this.intellisenseOpen = false;
            }
        } else {
            this.intellisenseOpen = false;
        }


        if (this.intellisenseOpen) {

            const coordinates = UtilitiesService.getCaretCoordinates(field, field.selectionEnd);
            const boundingRect = field.getBoundingClientRect();

            let left = (boundingRect.left + coordinates.left);
            let top = (boundingRect.top + coordinates.top + 20);

            let height = this.filteredQuickNotes.length * this.itemHeight;
            height += 10;

            if (height > this.intellisenseMaxHeight) {
                height = this.intellisenseMaxHeight;
            }

            if (left + this.intellisenseWidth > window.innerWidth) {
                left = window.innerWidth - this.intellisenseWidth;
            }

            if (top + height > window.innerHeight) {
                top = top - height - 20;
            }
            this.selectedQuickNote = this.filteredQuickNotes[0];
            this.intellisenseLeft = left + 'px';
            this.intellisenseTop = top + 'px';
            this.intellisenseHeight = height + 'px';

        }
    }

    setIntellisenseField(field: UserTextSnippet) {
        let fieldValue: string = this.intellisenseField.value;
        const caretPosition = this.intellisenseField.selectionEnd;
        const wordStartIndex = this.getWordStartIndex();
        const wordEndIndex = this.getWordEndIndex();

        const enter = fieldValue.lastIndexOf('\n', caretPosition);
        fieldValue = fieldValue.slice(0, wordStartIndex) + field.note + fieldValue.slice(wordEndIndex, fieldValue.length);
        this.intellisenseField.value = fieldValue;
        this.intellisenseField.selectionEnd = wordStartIndex + (field.note).length;
        this.intellisenseField.selectionStart = wordStartIndex + (field.note).length;
        this.intellisenseOpen = false;

        this.intellisenseField.dispatchEvent(new Event('input'));
    }

    inputBlurred(event) {
        if (!event.relatedTarget || !event.relatedTarget.classList || !event.relatedTarget.classList.contains('intellisense-button')) {
            this.intellisenseOpen = false;
        }
    }

    removeFormItem(formItem: FormItem) {
        this.selectedForm.formItems.splice(this.selectedForm.formItems.indexOf(formItem), 1);
    }

    get isFormComplete() {
        if (!this.selectedForm) {
            return false;
        }
        for (const formItem of this.selectedForm.formItems.filter(i => i.required)) {
            if (!formItem.answer) {

            }
        }

        return this.selectedForm.formItems.filter(i => i.required && !i.answer).length === 0;
    }

    raiseIsFormComplete() {
        this.formCompleteChange.emit(this.isFormComplete);
        setTimeout(() => this.formCompleteChange.emit(this.isFormComplete));
    }
}
