import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneLinkComponent } from './phone-link.component';
import { MatIconModule } from '@angular/material/icon';
import { PhoneLinkDialOptionsComponent } from '../phone-link-dial-options/phone-link-dial-options.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
    declarations: [PhoneLinkComponent],
    exports: [PhoneLinkComponent],
    imports: [
        CommonModule,
        MatIconModule,
        PhoneLinkDialOptionsComponent,
        MatDialogModule,
        MatButtonModule
    ]
})
export class PhoneLinkModule { }
