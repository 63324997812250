import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AddressApiService } from '@cogent/client/shared/services/api/address-api.service';
import { Address } from '@upkeeplabs/models/cogent';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SingleAddressEntryModalComponent } from '@cogent/client/shared/components/misc/single-address-entry-modal/single-address-entry-modal.component';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';

declare var google: any;

@Component({
    selector: 'app-single-address-entry',
    standalone: true,
    imports: [CommonModule, MaterialSharedModule, MatDialogModule],
    templateUrl: './single-address-entry.component.html',
    styleUrls: ['./single-address-entry.component.css']
})
export class SingleAddressEntryComponent implements OnChanges, OnInit {
    addressSearch: string;
    timout: any;
    id = UtilitiesService.newid();
    myControl = new FormControl('');
    filteredOptions: Observable<any[]>;
    @Input() address: Address;
    @Output() addressChange: EventEmitter<Address> = new EventEmitter();
    @Input() loadPropertyMeta: boolean;
    @Input() editMode = true;
    @Output() editModeChange: EventEmitter<boolean> = new EventEmitter();
    @Output() popupOpen: EventEmitter<boolean> = new EventEmitter();

    componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
    };

    constructor(private zone: NgZone,
        private dialog: MatDialog,
        private addressApi: AddressApiService) { }

    ngOnInit(): void {
        this.myControl.valueChanges.subscribe(v => {
            this.addressSearch = v;
            this.addressSearchChange(v);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.address && changes.address.currentValue) {
            if (this.address.address1) {
                this.editMode = false;
            }
        }
    }

    focus() {
        const ctrl = document.getElementById(this.id);
        if (!ctrl) {
            setTimeout(() => this.focus(), 100);
            return;
        }

        ctrl.focus();
    }

    reset() {
        this.editMode = true;
        this.editModeChange.emit(this.editMode);
        this.addressSearch = '';
    }

    get filteredOptionsAny(): any {
        return this.filteredOptions;
    }

    sendPopupOpen(value: boolean) {
        this.popupOpen.emit(value);
    }

    addressSearchChange(address: string | any) {
        if (!address || address.place_id) {
            return;
        }

        const service = new google.maps.places.AutocompleteService();
        const geolocation = {
            lat: 40.2980763,
            lng: -111.7080911
        };
        const circle = new google.maps.Circle({
            center: geolocation,
            radius: 1000,
        });
        const loc = new google.maps.LatLng(40.2980763, -111.7080911);

        clearTimeout(this.timout);
        this.timout = setTimeout(() => {
            service.getQueryPredictions({
                input: address,
                // location: geolocation,
                // radius: 1000,
                location: loc,
                radius: 3000,
                componentRestrictions: { country: "us" },
            }, (predictions, status) => {
                for (const p of predictions) {
                    p.iconClass = 'place'
                }
                this.zone.run(() => {
                    predictions.push({
                        iconClass: 'add',
                        description: `Can't find your address?`,
                        place_id: 'none',
                    })
                    this.filteredOptions = predictions;

                });
            });
        }, 500);

    }

    getFirstOption() {
        const filteredOptions = this.filteredOptions as any;
        if (filteredOptions && filteredOptions.length) {
            this.optionSelected({ option: { value: filteredOptions[0] } });
        }
    }

    loadingMeta = false;

    async optionSelected(option) {
        const placeId = option.option.value.place_id;

        if (placeId != 'none') {
            this.addressApi.getGoolePlaceDetail(placeId).then(async details => {
                const place = details.result;
                this.address = new Address();
                this.address.id = UtilitiesService.newid();
                let street = '';
                let route = '';
                let hasStreetNumber = false;

                for (let i = 0; i < place.address_components.length; i++) {
                    const addressType = place.address_components[i].types[0];
                    if (addressType === 'street_number') {
                        street = place.address_components[i][this.componentForm[addressType]];
                        hasStreetNumber = true;
                    } else if (addressType === 'route') {
                        route = place.address_components[i][this.componentForm[addressType]];
                    } else if (addressType === 'locality') {
                        this.address.city = place.address_components[i][this.componentForm[addressType]];
                    } else if (addressType === 'administrative_area_level_1') {
                        this.address.state = place.address_components[i][this.componentForm[addressType]];
                    } else if (addressType === 'country') {
                        this.address.country = place.address_components[i][this.componentForm[addressType]];
                    } else if (addressType === 'postal_code') {
                        this.address.postalCode = place.address_components[i][this.componentForm[addressType]];
                    }
                }
                if (!hasStreetNumber) {
                    const parts = this.address.address1.split(" ");
                    if (parts && parts.length > 0 && !isNaN(parts[0] as any)) {
                        street = parts[0];
                    }
                }
                this.address.address1 = street + ' ' + route;

                if (this.loadPropertyMeta) {
                    this.loadingMeta = true;
                    const item = await this.addressApi.getPropertyMeta(this.address.address1, this.address.postalCode);

                    this.address.squareFeet = item.sqft;
                    this.address.dwellingType = item.useCode;
                    this.address.latitude = item.lat;
                    this.address.longitude = item.lon;
                    this.address.yearBuilt = item.yearBuilt;
                    this.loadingMeta = false;

                }
                this.addressChange.emit(this.address);

                this.editMode = false;
                this.editModeChange.emit(this.editMode);
                this.addressSearch = '';
            });
        } else {
            this.addressSearch = '';
            const ref = this.dialog.open(SingleAddressEntryModalComponent, { data: {} });
            ref.afterClosed().subscribe(address => {
                if (address) {
                    this.address = address;
                    this.editMode = false;
                    this.editModeChange.emit(this.editMode);
                    this.addressChange.emit(this.address);
                }
            });
        }
    }
}
