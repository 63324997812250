<div class="my-container">
    <h2 class="sub-heading">Options</h2>


    <div class="options-grid">
        <button mat-button mat-raised-button (click)="dialNumber()">
            <mat-icon>phone</mat-icon><br>
            <h2 class="sub-heading">Dial</h2>
            
            <div class="info-2">Call through Amazon Connect</div>
        </button>

        <button  mat-raised-button (click)="showSMS()">
            <mat-icon>message</mat-icon><br>
            <h2 class="sub-heading">SMS</h2>
            <div class="info-2">Send an SMS Message</div>
        </button>
    </div>
    


</div>