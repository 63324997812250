import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MatDialog } from '@angular/material/dialog';
import { PhoneLinkDialOptionsComponent } from '../phone-link-dial-options/phone-link-dial-options.component';


@Component({
    selector: 'app-phone-link',
    templateUrl: './phone-link.component.html',
    styleUrls: ['./phone-link.component.css']
})
export class PhoneLinkComponent implements OnInit {

    @Input() phoneNumber: string;
    @Input() phoneNumberDescription: string;
    @Input() icon: string;
    @Input() alwaysShowNumber = false;
    @Output() dialed: EventEmitter<string> = new EventEmitter();
    @Input() autoDial: boolean;
    @Input() showSMSOption: boolean;
    @Input() showCopyButton = false;

    constructor(private dialogService: DialogsService,
        private dialog: MatDialog,
        private missionService: MissionService) { }

    ngOnInit() {
    }

    get display() {
        return this.phoneNumberDescription && !this.alwaysShowNumber ? this.phoneNumberDescription : UtilitiesService.formatPhoneNumber(this.phoneNumber);
    }

    dialNumber() {
        const formattedNumber = UtilitiesService.formatPhoneNumber(this.phoneNumber);
        if (this.autoDial) {
            this.doDial();
        } else if (this.showSMSOption) {
            const dialogRef = this.dialog.open(PhoneLinkDialOptionsComponent, { data: formattedNumber });
            dialogRef.afterClosed().subscribe(result => {
                console.log(result);
                if (result === 'dial') {
                    this.doDial();
                }
            });
        } else {
            this.dialogService.confirm('Dial', 'Dial: ' + formattedNumber).subscribe(result => {
                if (result) {
                    this.doDial()
                }
            });
        }
    }

    copyToClipboard() { 
        navigator.clipboard.writeText(this.phoneNumber);
        this.missionService.showToast('Phone number copied to clipboard');
    }

    private doDial() {
        const formattedNumber = UtilitiesService.formatPhoneNumber(this.phoneNumber);
        this.dialed.emit(formattedNumber);
        this.missionService.dialPhoneNumber(formattedNumber);
    }

}
