import { Injectable } from "@angular/core";
import { ApiService } from '@cogent/client/api';
import { EntityCommunication, EntityCommunicationItem, EntityCommunicationSummary, TaskMessage } from "@upkeeplabs/models/cogent";
import { UtilitiesService } from "../../logic/utilities";

@Injectable({ providedIn: 'root' })
export class SMSApiService {

    constructor(private api: ApiService) { }

    sendSMSProxyMessage(entityCommunication: EntityCommunication, entityCommunicationItem: EntityCommunicationItem) {
        return this.api.postSingleNode(`users/sms/send-proxy-message`, { entityCommunication, entityCommunicationItem });
    }

    getEntityCommunications(entityId: string) {
        return this.api.getArrayNode(`EntityCommunicationSummary`, { entityId_eq: entityId, orderby: 'LastMessageDate desc' }, () => new EntityCommunicationSummary());
    }

    getEntityCommunicationItems(entityCommunicationId: string) {
        return this.api.getArrayNode(`EntityCommunicationItem`, { entityCommunicationId_eq: entityCommunicationId, orderby: 'CreatedDate' }, () => new EntityCommunicationItem());
    }

    markCommunicationItemAsRead(entityCommunicationItem: EntityCommunicationItem) {
        entityCommunicationItem.wasRead = true;
        return this.api.patchNode(`EntityCommunicationItem/${entityCommunicationItem.id}`, { wasRead: true });
    }

    getUnreadCommunicationItems(entityId: string) {
        return this.api.getSingleNode(`users/sms/unread-proxy-messages/${entityId}`);
    }

    async getTaskMessagesForNumber(phoneNumber: string, max = 200, minDate: Date) {
        const number = UtilitiesService.stripPhoneFormatting(phoneNumber);

        const toMessages = await this.api.getArrayNode(`taskmessage`, { to_eq: number,createdDate_gte: minDate, orderby:  'createdDate desc', take: max }, () => new TaskMessage());
        const fromMessages = await this.api.getArrayNode(`taskmessage`, { from_eq: number,createdDate_gte: minDate, orderby: 'createdDate desc', take: max }, () => new TaskMessage());

     
        const results = toMessages.concat(fromMessages);

        results.sort((a, b) => a.createdDate > b.createdDate ? -1 : 1);
        return results;
    }
}