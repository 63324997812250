<div id="back-drop" (click)="emailBoxOpen = false" *ngIf="emailBoxOpen"></div>
<div flex style="position: relative">
  <div id="toolbar">
    <div flex>
      <div>
        <button mat-button (click)="print()">
          <i class="material-icons">print</i> Print </button>
        <button mat-button (click)="toggleEmailBox()" *ngIf="!hideEmail">
          <i class="material-icons">send</i> Email</button>
        <button mat-button (click)="doDownload()">
          <i class="material-icons">file_download</i> Download</button>
          <button mat-button (click)="doDownloadPDF()">
            <i class="material-icons">picture_as_pdf</i> PDF</button>

        <button mat-button *ngFor="let command of actionCommands" (click)="command.action(command)">
          <i class="material-icons" *ngIf="command.iconClass">{{command.iconClass}}</i>
          {{command.name}}
        </button>
        <a mat-button *ngFor="let command of routerCommands" [routerLink]="command.routerLink">
          <i class="material-icons" *ngIf="command.iconClass">{{command.iconClass}}</i>
          {{command.name}}
        </a>

      </div>
      <div nogrow>

        <button mat-button (click)="closeDocumentPreview()">
          <i class="material-icons">close</i></button>
      </div>
    </div>


  </div>

  <div style="padding-top: 44px; margin-bottom: -1px">
    <iframe appAutoHeight id="iFrame"></iframe>
  </div>
</div>
