<div *ngIf="editMode">
    <mat-form-field appearance="outline" *ngIf="!loadingMeta">
        <mat-label>
            <mat-icon>place</mat-icon>
            &nbsp;&nbsp;Address</mat-label>


        <!-- <mat-icon matPrefix>place</mat-icon> -->
        <input matInput autocomplete="do-not-auto-complete" [id]="id" [formControl]="myControl"
            [matAutocomplete]="auto" name="address">

        <mat-autocomplete (closed)="sendPopupOpen(false)" (opened)="sendPopupOpen(true)"
            (optionSelected)="optionSelected($event)" autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptionsAny" [value]="option">
                <mat-icon>{{option.iconClass}}</mat-icon> {{option.description}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="loadingMeta"
        style="height: 56px;padding-top: 3px;border: 1px solid #555;border-radius: 3px;padding-left: 7px;margin-bottom: 22px">
        <mat-spinner class="thirty"></mat-spinner> Loading Property Details
    </div>
</div>
<div *ngIf="!editMode" aflex bottommargin30>

    <address>
        {{address.address1}}<br>
        {{address.address2}}<br *ngIf="address.address2">
        {{address.city}}, {{address.state}} {{address.postalCode}}


    </address>

    <button mat-button nogrow (click)="editMode = true; this.editModeChange.emit(true)">
        <mat-icon>edit</mat-icon>
    </button>
</div>